import React, { useState, useMemo } from 'react'
import { SubCardLink } from './SubCardLink'
import { useTSelector } from '../../../../../utils/hooks/reduxHooks'
import { amplitudeLogEvent } from '../../../../../amplitude'
import { CardLinkWrapper } from './CardLinkWrapper/CardLinkWrapper'
import { CardLinkTooltip } from './CardLinkTooltip/CardLinkTooltip'
import { DataLink } from '../constants'

import cn from 'classnames'
import * as Icon from '../../../../UI/icons'
import './CardLink.scss'

export const CardLink = React.memo<PropsType>(({
  currentPathUrl,
  dataLink,
  disabledSidebar,
  withCount,
}) => {
  const { title, icon, pathUrl, inTabs, id, amplitudeEvent } = dataLink

  const [showNotification, setShowNotification] = useState(false)
  const [isOpenTabs, setIsOpenTabs] = useState(
    inTabs && inTabs.map(({ pathUrl }) => pathUrl).includes(currentPathUrl)
  )

  const userInfo = useTSelector(state => state.user.userInfo)
  const isOpenSidebar = useTSelector(state => state.app.isOpenedLeftSidebar)

  const linkTo = (inTabs || disabledSidebar) ? '#' : pathUrl!

  const isActiveCardLink = currentPathUrl === pathUrl ||
    inTabs && !isOpenTabs && inTabs.map(({ pathUrl }) => pathUrl).includes(currentPathUrl)

  const tabsWrapperHeight = useMemo(() => {
    return inTabs?.length && isOpenTabs ? `${inTabs?.length * 6.8}rem` : '0'
  }, [inTabs, isOpenTabs])

  const renderLinkBody = () => (
    <>
      <div
        className={cn('card-link__icon', {
          'disabled-icon': disabledSidebar,
          'active-icon': isActiveCardLink && !disabledSidebar
        })}
        onClick={() => !disabledSidebar && setIsOpenTabs(state => !state)}
      >
        {icon}
      </div>

      <li
        className={cn('card-link', {
          'card-link_active': isActiveCardLink && !disabledSidebar,
          'card-link_reduce': !isOpenSidebar,
          'card-link_main-tab': !!inTabs && isOpenTabs,
          'card-link_disabled': disabledSidebar,
        })}
        id={id}
        onMouseEnter={() => setShowNotification(true)}
        onClick={() => {
          amplitudeLogEvent({ event: amplitudeEvent, options: userInfo })
          !disabledSidebar && setIsOpenTabs(state => !state)}
        }
      >
        <span>{title}</span>
        {!!withCount && (
          <div className={cn('card-link_count-toification', {
            'card-link_count-toification-big-number': withCount > 99
          })}>
            <p>{withCount <= 99 ? withCount : '99+'}</p>
          </div>
        )}
        {inTabs && (
          <Icon.ChevronWithoutBorder
            className={cn('chevron', { 'active-tabs': isOpenTabs })}
            color='rgba(0, 0, 0, 0.64)'
          />
        )}
        {inTabs && (
          <Icon.CircleChevron
            className={cn('folded-icon', {
              'folded-icon_hidden': isOpenSidebar,
              'folded-icon_open': isOpenTabs
            })}
            width='2.4'
            height='2.4'
            onClick={() => disabledSidebar && setIsOpenTabs(state => !state)}
          />
        )}
      </li>

      <div
        className={cn('tabs_wrapper', {
          'tabs_wrapper__active': isOpenTabs && Array.isArray(inTabs) && inTabs.length > 0
        })}
        style={{ height: tabsWrapperHeight }}
      >
        {inTabs?.map(tabLink => (
          <SubCardLink
            key={tabLink.title}
            title={tabLink.title}
            pathUrl={tabLink.pathUrl ?? '/'}
            amplitudeEvent={tabLink.amplitudeEvent}
            id={tabLink.id}
            icon={tabLink.icon}
            setShowHeadLinkNotification={setShowNotification}
            isOpenSidebar={isOpenSidebar}
            currentPathUrl={currentPathUrl}
          />
        ))}
      </div>
    </>
  )

  return (
    <>
      <CardLinkWrapper
        key={id}
        id={id}
        to={linkTo}
        className={cn('wrapper__card-link', {
          'disabled': disabledSidebar
        })}
        condition={!!inTabs}
        onMouseEnter={() => setShowNotification(true)}
        onMouseLeave={() => setShowNotification(false)}
      >
        {renderLinkBody()}
      </CardLinkWrapper>

      <CardLinkTooltip
        cardLinkId={id}
        cardLinkTitle={title}
        isDisabledTooltip={!showNotification || isOpenSidebar}
      />
    </>
  )
})

type PropsType = {
  currentPathUrl: string
  selected?: boolean
  userId?: number
  dataLink: DataLink
  disabledSidebar: boolean
  withCount: number | undefined
}