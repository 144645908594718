import React from 'react'
import { IIcon } from '../../../models/IIcon'

const Puzzle: React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#949494',
  ...innerProps
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.36 11H19V4.99999H13V3.63999C13 2.32999 12.06 1.13999 10.76 1.00999C9.26 0.859987 8 2.02999 8 3.49999V4.99999H2.01V10.8H3.4C4.71 10.8 5.9 11.68 6.15 12.96C6.48 14.68 5.17 16.2 3.5 16.2H2V22H7.8V20.6C7.8 19.29 8.68 18.1 9.96 17.85C11.68 17.52 13.2 18.83 13.2 20.5V22H19V16H20.5C21.97 16 23.14 14.74 22.99 13.24C22.86 11.94 21.66 11 20.36 11Z" fill={color} />
  </svg>
)
export default Puzzle