import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useAuthRedirect } from './hooks/useAuthRedirect'
import { routes } from './routesConfig'
import { useFunctionsAccess } from '../../features/FunctionAccess'

import Loader from '../../../(deprecated)/components/UI/Loader/Loader'

export const AppRoutes = () => {
  const approveFunctions = useFunctionsAccess()
  const { isRedirectToAuth, authLocationInfo } = useAuthRedirect()

  return (
    <Suspense fallback={<Loader style={{ width: '100%' }} />}>
      {isRedirectToAuth && <Redirect to={authLocationInfo} />}

      <Switch>
        {routes.map(({ accessFunctions, ...routeProps }, i) => {
          const isRouteAccessible = approveFunctions.some(apFn => accessFunctions?.includes(apFn))
          return (
            !accessFunctions.length || isRouteAccessible
              ? <Route key={routeProps.path} {...routeProps} />
              : null
          )
        })}

        <Redirect to='/not-found' />
      </Switch>
    </Suspense>
  )
}