import React from 'react'
import { HoverContainer } from '../HoverContainer/HoverContainer'
import { Close } from '../../ui-icons'
import './ModalHeader.scss'

export const ModalHeader = React.memo<PropsType>(({
  onClose,
  title
}) => (
    <div className='add-company-modal-header'>
      <h2 className='title'>{title}</h2>
      <HoverContainer onClick={onClose}>
        <Close />
      </HoverContainer>
    </div>
  )
)

type PropsType = {
  title: string
  onClose: () => void
}