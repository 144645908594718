import React, { useEffect } from 'react'
import { useTDispatch, useTSelector } from '../../../../../(deprecated)/utils/hooks/reduxHooks'
import { useLocation } from 'react-router-dom'
import { LeftSidebar } from '../../../../../(deprecated)/components/Sidebar/LeftSidebar/LeftSidebar'
import { getToken } from '../../../../../(deprecated)/utils/localStorageManagement'
import { isEmpty } from 'lodash'
import { websocketInit } from '../../../../../(deprecated)/utils/websockets/actionCreators/actionCreators'
import { MobileAppsDownloadBanner } from '../../../../shared/ui-component/MobileAppsDownloadBanner/MobileAppsDownloadBanner'

import SidebarWrapper from '../../../../../(deprecated)/components/UI/wrappers/SidebarWrapper/SidebarWrapper'
import * as InitSettingsWebSocket from '../../../../../(deprecated)/utils/websockets/instance'
import cn from 'classnames'

import './MainLayout.scss'


export const MainLayout:React.FC<PropsType> = ({ children }) => {
  const dispatch = useTDispatch()
  const location = useLocation()

  const isOpenedLeftSidebar = useTSelector(state => state.app.isOpenedLeftSidebar)
  const userInfo = useTSelector(state => state.user.userInfo)

  useEffect(() => {
    // todo нужно переписать условие при котором диспатчится websocketInit(сейчас на каждое изменение location.pathname)
    if (getToken() && !isEmpty(userInfo) && ![location.pathname].includes('/sign-up') && ![location.pathname].includes('/auth')) {
      dispatch(websocketInit([
        InitSettingsWebSocket.notificationInitSettings,
        InitSettingsWebSocket.chatChannelsInitSettings,
        InitSettingsWebSocket.chatMessagesInitSettings,
        InitSettingsWebSocket.documentsCommentsSettings
      ]))
    }
  }, [location.pathname, userInfo])

  return (
    <div
      className={cn(
        'main-layout',
        `main-layout-${window.location.pathname.replace('/', '')}-page`,
        `main-layout-${window.location.pathname.split('/')?.[1]}-page`
      )}
    >
      <SidebarWrapper component={<LeftSidebar />} />
      <div
        className={cn( 'main-layout__work-flow', {
          'main-layout__work-flow_open-sidebar':  isOpenedLeftSidebar,
        })}
      >
        <div className='work-flow__wrapper'>
          {children}
          <div id='popup-container'></div>
        </div>

        <MobileAppsDownloadBanner />
      </div>
    </div>
  )
}

type PropsType = {
  children: React.ReactNode | React.ReactNode[]
}