import React from 'react'
import { IIcon } from '../../../models/IIcon'

const StrikeEditor: React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  ...innerProps
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.85 7.08C6.85 4.37 9.45 3 12.24 3C13.88 3 15.24 3.49 16.14 4.28C16.91 4.93 17.6 6.01 17.6 7.52H14.59C14.59 7.21 14.54 6.93 14.44 6.67C14.15 5.81 13.24 5.39 12.19 5.39C10.33 5.39 9.85 6.41 9.85 7.09C9.85 7.57 10.1 7.97 10.59 8.3C10.97 8.55 11.36 8.78 12 9H7.39C7.18 8.66 6.85 8.11 6.85 7.08ZM21 12V10H3V12H12.62C13.77 12.45 14.58 12.75 14.58 13.97C14.58 14.97 13.77 15.64 12.3 15.64C10.76 15.64 9.37 15.1 9.37 13.13H6.4C6.4 13.68 6.48 14.26 6.64 14.71C7.45 17 9.93 18.01 12.31 18.01C14.58 18.01 17.61 17.12 17.61 13.96C17.61 13.66 17.6 12.8 17.13 12.02H21V12Z" fill="#5C5C5C" />
  </svg>
)
export default StrikeEditor