import React, { useState } from 'react'
import { HoverContainer } from '../../../../../fsd/shared/ui-component'
import { useTSelector } from '../../../../utils/hooks/reduxHooks'
import { IOldUser } from '../../../../models/IUser'
import { getShortName } from '../../../../utils/getPersonName'
import { useHistory } from 'react-router-dom'
import { amplitudeLogEvent } from '../../../../amplitude'
import { AMPLITUDE_EVENTS } from '../../../../amplitude/events'
import { EDIT_PROFILE_ROUTE } from '../../../pages/Profile/constant'

import useHandleClickOutside from '../../../../utils/hooks/useHandleClickOutside'
import Avatar from '../../../UI/Avatar/Avatar'
import cn from 'classnames'
import * as Icons from '../../../UI/icons'

import './LeftSidebarProfile.scss'

export const LeftSidebarProfile = React.memo(() => {
  const history = useHistory()

  const userInfo = useTSelector((state: any) => state.user.userInfo) as IOldUser
  const isOpen = useTSelector(state => state.app.isOpenedLeftSidebar)

  const [isOpenPopup, setIsOpenPopup] = useState(false)

  useHandleClickOutside(['.profile-button'], () => setIsOpenPopup(false))

  const onClickBtnPopup = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, type: 'profile' | 'company') => {
    e.stopPropagation()
    setIsOpenPopup(false)
    if (type == 'profile') {
      amplitudeLogEvent({
        event: AMPLITUDE_EVENTS.LEFT_SIDEBAR_ACTIVE_USER_PROFILE_BTN_CLICK,
        options: userInfo
      })
      history.push(EDIT_PROFILE_ROUTE)
    }
    if (type == 'company') {
      amplitudeLogEvent({
        event: AMPLITUDE_EVENTS.LEFT_SIDEBAR_MY_COMPANY_BTN_CLICK,
        options: userInfo
      })
      history.push(`/company/${userInfo.company.id}`)
    }
  }

  return (
    <div
      className={cn('profile-button', { close: !isOpen })}
      onClick={() => setIsOpenPopup(state => !state)}
    >
      <div className={cn('profile-popup', { open: isOpenPopup })}>
        <div className='profile-popup_btn' onClick={(e) => onClickBtnPopup(e, 'profile')}>
          <Icons.Profile />
          <p>Профиль</p>
        </div>
        <div className='profile-popup_btn' onClick={(e) => onClickBtnPopup(e, 'company')}>
          <Icons.Bag />
          <p>Моя компания</p>
        </div>
      </div>
      <Avatar size='s' className='avatar' />
      <div className='profile-button__info'>
        <p className='name'>{getShortName(userInfo)}</p>
        <p className='company'>{userInfo.company?.title}</p>
      </div>
      <HoverContainer className='svg-menu'>
        <Icons.VerticalMenu />
      </HoverContainer>
    </div>
  )
})
