import React, { CSSProperties } from 'react'
import { IIcon } from '../../../models/IIcon'

interface ITickIcon extends IIcon {
  style?: CSSProperties
}

const LinkArrowInSquare:React.FC<ITickIcon> = ({
  width= '1.8',
  height = '1.8',
  color= '#5C5C5C',
  ...innerProps
}) => (
  <svg {...innerProps}  width={`${width}rem`} height={`${height}rem`} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 16H2V2H9V0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.89 18 2 18H16C17.1 18 18 17.1 18 16V9H16V16ZM11 0V2H14.59L4.76 11.83L6.17 13.24L16 3.41V7H18V0H11Z" fill={color}/>
  </svg>
)

export default LinkArrowInSquare