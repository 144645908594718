import React, { useMemo } from 'react'
import { createPortal } from 'react-dom'
import cn from 'classnames'
import PropTypes from 'prop-types'

import useHandleClickOutside from '../../../utils/hooks/useHandleClickOutside'
import * as Icon from '../icons'
import variables from '../../../styles/variables.scss'

import './modal.scss'

const rootEl = document.getElementById('root')

const Modal = ({
  start,
  className,
  title,
  children,
  bgClassName,
  onClose = () => console.log('close'),
  isHiddenModal,
  setIsHiddenModal,
  style,
}) => {
  const splitedClassNames = useMemo(() => (
    className.split(' ').map(cl => '.' + cl)
  ), [className])

  useHandleClickOutside(['.modal-card', ...splitedClassNames], setIsHiddenModal || onClose, true)

  if (!start) return <></>

  return (
    <div className={cn('modal-bg', bgClassName, { 'modal-bg_hidden': isHiddenModal })}>
      <div className={cn('modal-card', className)} style={style}>
        <div className='modal-card__header'>
          <h2>{title}</h2>
          <Icon.Close
            onClick={setIsHiddenModal || onClose}
            color={variables.darkGray}
            className='btn btn_hover'
          />
        </div>
        {children}
      </div>
    </div>
  )
}

const ModalWrapper = ({ start, ...props }) => start
  ? createPortal(<Modal start={start} {...props} />, rootEl)
  : null

ModalWrapper.propTypes = {
  start: PropTypes.bool,
}

Modal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
}

export default ModalWrapper
