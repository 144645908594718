import * as TYPE from './actionTypes.js'

export const toggleSidebar = () => ({ type: TYPE.TOGGLE_SIDEBAR })

export const setIsLoadingFlowTemplate = () => ({
  type: TYPE.SET_LOADING_FLOW_TEMPLATES
})

export const getDocumentFlowTemplateSuccess = (types) => ({
  type: TYPE.GET_FLOW_TEMPLATES_SUCCESS,
  payload: types
})

export const setLoadingFlowStageTitle = () => ({
  type: TYPE.SET_LOADING_FLOW_STAGE_TITLE
})

export const getFlowStageTitleSuccess = (titles) => ({
  type: TYPE.GET_FLOW_STAGE_TITLE_SUCCESS,
  payload: titles
})

export const getFlowStageTitleFailed = () => ({
  type: TYPE.GET_FLOW_STAGE_TITLE_FAILED
})

export const setLoadingFlowActions = () => ({
  type: TYPE.SET_LOADING_FLOW_ACTION_START
})

export const getFlowActionSuccess = data => ({
  type: TYPE.GET_FLOW_ACTION_SUCCESS,
  payload: data
})

export const getFlowActionFailed = () => ({
  type: TYPE.GET_FLOW_ACTION_FAILED
})

export const getDocumentFlowTemplateFailed = () => ({
  type: TYPE.GET_FLOW_TEMPLATES_FAILED
})

export const setActiveProject = (id) => ({
  type: TYPE.SET_ACTIVE_PROJECT,
  payload: id
})

export const setLoadingGetDocumentForm = () => ({
  type: TYPE.SET_LOADING_GET_DOCUMENT_FORM
})

export const getDocumentFormSucces = (data) => ({
  type: TYPE.GET_DOCUMENT_FORM_SUCCESS,
  payload: data
})

export const getDocumentFormFailed = () => ({
  type: TYPE.GET_DOCUMENT_FORM_FAILED,
})

export const setLoadingGetMembersRole = () => ({
  type: TYPE.SET_LOADING_GET_MEMBERS_ROLE
})
export const getMembersRoleSuccess = (data) => ({
  type: TYPE.GET_MEMBERS_ROLE_SUCCESS,
  payload: data
})
export const getMembersRoleFailed = () => ({
  type: TYPE.GET_MEMBERS_ROLE_FAILED
})

export const setLoadingGetTemplateExcel = () => ({
  type: TYPE.SET_LOADING_GET_TEMPLATE_EXCEL
})
export const getTemplateExcelSuccess = (data) => ({
  type: TYPE.GET_TEMPLATE_EXCEL_SUCCESS,
  payload: data
})
export const getTemplateExcelFailed = () => ({
  type: TYPE.GET_TEMPLATE_EXCEL_FAILED
})
export const clearStore = () => ({
  type: TYPE.RESET_STORE
})
export const setMobileInfo = (data) => ({
  type: TYPE.SET_MOBILE_INFO,
  payload: data
})