import { combineReducers } from 'redux'

import { documentsReducer } from './documents/reducer'
import { userReducer } from './user/reducer'
import { projectReducer } from './project/reducer'
import { fileReducer } from './files/reducer'
import { userDocumentsReducer } from './userDocuments/reducer'
import { imageReducer } from './images/reducer'
import { companyReducer } from './company/reducer'
import { usersProjectReducer } from './userProject/reducer'
import { notificationReducer } from './notifications/reducer'
import { allCompaniesReducer } from './allCompanies/reducer'
import { locationReducer } from './locations/reducer'
import { adminReducer } from './admin/reducer'
import { chatReducer } from './chat/reducer'
import { appNotificationReducer } from './appNotification/reducer'
import { templatesReducer } from './templates/reducer'
import { licensesReducer } from './licenses/reducer'

import { projectUserApi } from '../api/projectUserApi/projectUserApi'
import { locationTreeApi } from '../api/locationTreeApi/locationTreeApi'
import { companyApi } from '../api/companyApi/companyApi'
import { projectApi } from '../api/projectApi/projectApi'
import { systemUserApi } from '../api/systemUserApi/systemUserApi'
import { projectRoleApi } from '../../fsd/shared/rtk/ProjectRole/ProjectRoleApi'
import { carCheckpointsApi } from '../api/carCheckpointApi/carCheckpointApi'
import { userCheckpointsApi } from '../api/userCheckpointApi/userCheckpointApi'
import { projectLocationsApi } from '../../fsd/shared/rtk/ProjectLocation/ProjectLocationApi'
import { checkpointLogsApi } from '../../fsd/shared/rtk/CheckpointLogs/checkpointLogsApi'
import { enableMapSet } from 'immer'

import meetReducer from './meet/meetSlice'
import modelReducer from './model/modelSlice'
import docsReducer from './docs/docsSlice'
import docsTemplates from './docsTemplates/docsTemplatesSlice'
import workerReducer from './worker/workerSlice'
import checkboxForDocsShort from '../../fsd/shared/reducers/docsShort/checkboxForDocsShort/slice'
import backlightForDocShort from '../../fsd/shared/reducers/docsShort/backlightForDocsShort/slice'

import actionOrderSlice from './actionOrder/actionOrderSlice'
import templateSignerSettingsSlice from './templateSignerSettings/templateSignerSettingsSlice'
import docsFilterReducer from './docsFilter/docsFilterSlice'

import passesFiltersSlice from './kpp/passesFiltersSlice'
import passActiveDataSlice from './kpp/passActiveDataSlice'

import appReducer from './app/reducer'

import projectRoleSlice from '../../fsd/shared/reducers/projectRole/slice'
import checkpointLogsSlice from '../../fsd/shared/reducers/checkpointLogs/slice'

enableMapSet()

const rootReducer = combineReducers({
  [projectUserApi.reducerPath]: projectUserApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  [projectApi.reducerPath]: projectApi.reducer,
  [systemUserApi.reducerPath]: systemUserApi.reducer,
  [projectRoleApi.reducerPath]: projectRoleApi.reducer,
  [carCheckpointsApi.reducerPath]: carCheckpointsApi.reducer,
  [userCheckpointsApi.reducerPath]: userCheckpointsApi.reducer,
  [projectLocationsApi.reducerPath]: projectLocationsApi.reducer,
  [locationTreeApi.reducerPath]: locationTreeApi.reducer,
  [checkpointLogsApi.reducerPath]: checkpointLogsApi.reducer,
  documents: documentsReducer,
  user: userReducer,
  app: appReducer,
  project: projectReducer,
  file: fileReducer,
  images: imageReducer,
  userDocuments: userDocumentsReducer,
  companyInfo: companyReducer,
  allUsers: usersProjectReducer,
  notification: notificationReducer,
  allCompanies: allCompaniesReducer,
  worker: workerReducer,
  locations: locationReducer,
  admin: adminReducer,
  chat: chatReducer,
  appNotification: appNotificationReducer,
  templates: templatesReducer,
  licenses: licensesReducer,
  templateSignerSettings: templateSignerSettingsSlice,
  meet: meetReducer,
  model: modelReducer,
  actionOrder: actionOrderSlice,
  docs: docsReducer,
  docsFilter: docsFilterReducer,
  passesFilter: passesFiltersSlice,
  passActiveData: passActiveDataSlice,
  docsTemplates: docsTemplates,
  projectRole: projectRoleSlice,
  checkboxForDocsShort: checkboxForDocsShort,
  backlightForDocShort: backlightForDocShort,
  checkpointLogs: checkpointLogsSlice,
})

export default (state, action) => rootReducer(action.type === 'RESET_STORE' ? undefined : state, action)
