import { AttachmentAdapter, IAttachmentModel } from '../../../../(deprecated)/api/AttachmentAdapter'
import { ILocationModel, LocationAdapter } from '../../../../(deprecated)/api/LocationAdapter'
import camelcaseFields from '../../../../(deprecated)/utils/formatFields'

export interface ICheckpointLogModel {
  id: number
  visitingStart: string
  visitingEnd: string | null
  status: string
  comment: string
  carNumber?: string
  carBrand?: string
  location: ILocationModel
  attachments: IAttachmentModel[] | null
  contactUser?: string
  contactPhone?: string
  project: number
  // создатель записи в журнале
  creatorId: number
  creatorProfile: {
    fullName: string
    firstName: string
    middleName?: string
    lastName: string
    position: string
  }
  // информация о пропуске
  passId: number
  passAccessDateStart: string
  passAccessDateEnd: string
  passCreatorId: number
  passCreatorProfile: {
    fullName: string
    firstName: string
    middleName?: string
    lastName: string
    position: string
  }
  // информация о посетителе
  visitorId: number | null
  visitorProfile: {
    fullName?: string
    firstName?: string
    middleName?: string
    lastName?: string
    imageId?: number
    imageUrl?: string
    companyTitle?: string
    companyType?: string
    companyFullInfo?: string
    position?: string
  }
}

export class CheckpointLogAdapter {
  private model: ICheckpointLogModel = {
    id: NaN,
    visitingStart: '',
    visitingEnd: '',
    location: new LocationAdapter({}).getFields(),
    status: '',
    comment: '',
    attachments: null,
    creatorId: NaN,
    carNumber: '',
    carBrand: '',
    contactUser: '',
    contactPhone: '',
    project: NaN,
    creatorProfile: {
      firstName: '',
      middleName: '',
      lastName: '',
      fullName: '',
      position: ''
    },
    passId: NaN,
    passAccessDateStart: '',
    passAccessDateEnd: '',
    passCreatorId: NaN,
    passCreatorProfile: {
      firstName: '',
      middleName: '',
      lastName: '',
      fullName: '',
      position: ''
    },
    visitorId: NaN,
    visitorProfile: {
      firstName: '',
      middleName: '',
      lastName: '',
      fullName: '',
      imageId: NaN,
      imageUrl: '',
      companyTitle: '',
      companyType: '',
      companyFullInfo: '',
      position: ''
    }
  }

  constructor(logRecord: any) {
    const camelLogRecord = camelcaseFields(logRecord)

    try {
      this.model.id = camelLogRecord.id
      this.model.visitingStart = camelLogRecord.visitingStart
      this.model.visitingEnd = camelLogRecord.visitingEnd
      this.model.status = camelLogRecord.document.status
      this.model.project = camelLogRecord.document.project
      this.model.comment = camelLogRecord.document.comment
      this.model.carNumber = camelLogRecord.document.number
      this.model.carBrand = camelLogRecord.document.brand
      this.model.contactUser = camelLogRecord.document.contactUser
      this.model.contactPhone = camelLogRecord.document.contact
      this.model.location = new LocationAdapter(camelLogRecord.document.location).getFields()
      this.model.attachments = camelLogRecord.document.attachments
        .map((entity: any) => new AttachmentAdapter(entity).getFields())

      this.model.creatorId = camelLogRecord.creator.id
      this.model.creatorProfile = camelLogRecord.creator.profile
      this.model.creatorProfile.fullName =
        camelLogRecord.creator.profile.lastName + ' ' +
        camelLogRecord.creator.profile.firstName + ' ' +
        camelLogRecord.creator.profile.middleName

      this.model.passId = camelLogRecord.document.id
      this.model.passAccessDateStart = camelLogRecord.document.accessDateStart
      this.model.passAccessDateEnd = camelLogRecord.document.accessDateEnd
      this.model.passCreatorId = camelLogRecord.document.creator.id
      this.model.passCreatorProfile = camelLogRecord.document.creator.profile
      this.model.passCreatorProfile.fullName =
        camelLogRecord.document.creator.profile.lastName + ' ' +
        camelLogRecord.document.creator.profile.firstName + ' ' +
        camelLogRecord.document.creator.profile.middleName

      if (camelLogRecord.document.visitor.profile) {
        this.model.visitorId = camelLogRecord.document.visitor.id

        this.model.visitorProfile = {
          firstName: camelLogRecord.document.visitor.profile.firstName,
          middleName: camelLogRecord.document.visitor.profile.middleName,
          lastName: camelLogRecord.document.visitor.profile.lastName,
          imageId: camelLogRecord.document.visitor.profile.image?.id,
          imageUrl: camelLogRecord.document.visitor.profile.image?.url,
          companyTitle: camelLogRecord.document.visitor.profile.company?.title,
          companyType: camelLogRecord.document.visitor.profile.company?.type,
          position: camelLogRecord.document.visitor.profile.position,
          fullName:
            camelLogRecord.document.visitor.profile?.lastName + ' ' +
            camelLogRecord.document.visitor.profile?.firstName + ' ' +
            camelLogRecord.document.visitor.profile?.middleName
        }
      }

      if (camelLogRecord.document.visitor.fullName) {
        this.model.visitorProfile.fullName = camelLogRecord.document.visitor.fullName
      }

      if (camelLogRecord.document.visitor.profile?.company?.title)
        this.model.visitorProfile.companyFullInfo =
          camelLogRecord.document.visitor.profile.company?.type +
          ` «${camelLogRecord.document.visitor.profile.company?.title}»`

    } catch (e) {
      console.error('!!!', e)
    }
  }

  getFields() {
    return this.model
  }
}