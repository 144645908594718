import React from 'react'
import { IIcon } from '../../../models/IIcon'

const UserInCircle: React.FC<IIcon> = ({
  width = '13.5',
  height = '12.7',
  ...innerProps
}) => (
<svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 135 127" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.499878" y="8.58301" width="118.192" height="118.192" rx="59.096" fill="#E8F1FB"/>
  <g clipPath="url(#clip0_1377_25968)">
    <path d="M84.2654 49.4597C84.2654 54.9087 79.8524 59.3217 74.4034 59.3217C68.9544 59.3217 64.5414 54.9087 64.5414 49.4597C64.5414 44.0107 68.9544 39.5977 74.4034 39.5977C79.8524 39.5977 84.2654 44.0107 84.2654 49.4597ZM53.6793 76.6148C53.6793 75.1762 54.392 73.8422 55.7323 72.6051C57.0795 71.3618 58.993 70.2852 61.1943 69.4033C65.5986 67.639 70.9032 66.7527 74.4034 66.7527C77.9036 66.7527 83.2082 67.639 87.6124 69.4033C89.8138 70.2852 91.7273 71.3618 93.0744 72.6051C94.4148 73.8422 95.1274 75.1762 95.1274 76.6148C95.1274 79.0619 93.1436 81.0458 90.6964 81.0458H58.1103C55.6632 81.0458 53.6793 79.0619 53.6793 76.6148Z" stroke="#1A65DA" stroke-width="2"/>
  </g>
  <rect x="15.3065" y="1.22485" width="118.194" height="118.194" rx="59.0968" stroke="#1A65DA" stroke-width="2" stroke-linecap="round" stroke-dasharray="10 15"/>
  <defs>
    <clipPath id="clip0_1377_25968">
      <rect width="65.1722" height="65.1722" fill="white" transform="translate(41.8173 27.7356)"/>
    </clipPath>
  </defs>
</svg>
)
export default UserInCircle