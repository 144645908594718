import React from 'react'

const Exit = ({ width = '24', height = '24', color = '#3877AF', opacity = '1', ...innerProps }) => (
  <svg {...innerProps} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.51416 20H4.51416C3.40959 20 2.51416 19.1046 2.51416 18V6C2.51416 4.89543 3.40959 4 4.51416 4H8.51416V6H4.51416V18H8.51416V20Z" fill={color}/>
    <path d="M15.6438 6L14.2236 7.4082L18.1428 11.3608L8.99988 11.3608C8.44759 11.3608 7.99988 11.8086 7.99988 12.3608C7.99988 12.9131 8.4476 13.3608 8.99989 13.3608L18.1621 13.3608L14.1816 17.3076L15.5898 18.7278L21.9807 12.3909L15.6438 6Z" fill={color}/>
  </svg>
)

export default Exit
