import { format, parse, parseISO, addMinutes } from 'date-fns';
import { ru } from 'date-fns/locale'
import moment from 'moment';

export const parseDate = dateStr => Date.parse(dateStr)

export const formatDateFromString = {
  toStandartDate: (date) => (format(date, 'yyyy-MM-dd', { locale: ru })),
  toShortDate: (date = '') => format(parseDate(date), 'dd.MM.yyyy', { locale: ru }),
  toExpandedDate: (date = '') => format(parseDate(date), 'dd MMM yyyy', { locale: ru }),
  toStringDate: (date = '') => format(date, 'dd.MMM.yyyy'),
  toTableDate(date = '') {
    if (this.toStringDate(parseISO(date)) === this.toStringDate(new Date())) {
      return format(parseISO(date), 'H:mm')
    }
    return format(parseISO(date), 'dd MMM', { locale: ru })
  },
  toTimeAndDate: (date = '') => {
    const dateCurrentTimeZone = parseISO(date)
    return {
      time: format(dateCurrentTimeZone, 'HH:mm'),
      date: format(dateCurrentTimeZone, 'dd.MM.yyyy')
    }
  },
  toStandartDayAndMoutn: (date) => format(parseDate(date), 'dd MMMM', { locale: ru }),
  toYear: (date) => moment(date).locale('ru').format('yyyy'),
  toStandardTime: (date) => moment(date).format('HH:mm')
}