import React from 'react'
import { Button, Modal, ModalHeader } from '../index'

import './ModalConfirm.scss'

export const ModalConfirm: React.FC<PropType> = ({
  title,
  text,
  isLoading,
  buttonsText,
  isOpen,
  setOpen,
  onConfirm
}) => (
  <Modal
    open={isOpen}
    onCancel={() => setOpen(false)}
    className='wide-exclude-users-from-project__modal'
    width='48rem'
  >
    <ModalHeader
      title={title}
      onClose={() => setOpen(false)}
    />
    <p className='wide-exclude-users-from-project__modal_content'>
      {text}
    </p>
    {/* TODO использовать modalSaveButtons из массовой настройки */}
    <div className='wide-exclude-users-from-project__modal_btns'>
      <Button
        title={buttonsText[0]}
        buttonType='blue-ligth'
        onClick={() => setOpen(false)}
      />
      <Button
        title={buttonsText[1]}
        isLoading={isLoading}
        onClick={onConfirm}
      />
    </div>
  </Modal>
)

type PropType = {
  title: string,
  isLoading?: boolean
  text: string | React.ReactNode
  buttonsText: [string, string]
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
  onConfirm: (e?: React.MouseEvent) => void
}
