import { ICarCheckpointModel, CarCheckpointAdapter } from './CarCheckpointAdapter'
import { omitBy, isUndefined } from 'lodash'

export class PutChekpointByIdQuery {
  query = (carCheckpoint: ICarCheckpointModel & { projectId: number }) => {
    const postData = omitBy({
      id: carCheckpoint.id,
      access_date_start: carCheckpoint.accessDateStart,
      access_date_end: carCheckpoint.accessDateEnd,
      project: carCheckpoint.projectId,
      comment: carCheckpoint.comment || undefined,
      registered_visitor: carCheckpoint.registeredVisitor
        ? carCheckpoint.registeredVisitor
        : undefined,
      guest: carCheckpoint.registeredVisitor ? undefined : carCheckpoint.guest,
      contact_user: carCheckpoint.contactUser || undefined,
      contact_phone: carCheckpoint.contactPhone || undefined,
      location: {
        location: carCheckpoint.location.location,
        exact_location: carCheckpoint.location.exactLocation
      },
      attachments: carCheckpoint.attachments.map(a => a.attachmentId),
      number: carCheckpoint.number,
      brand: carCheckpoint.brand
    }, isUndefined)

    return {
      url: `checkpoints/vehicles/${postData.id}`,
      method: 'PUT',
      body: postData
    }
  }

  transformResponse = (response: any) => {
    return new CarCheckpointAdapter(response).getFields()
  }
}