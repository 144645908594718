import { isEmpty } from 'lodash'

export interface ILocationModel {
  location: number[]
  exactLocation: number[]
}

export class LocationAdapter {
  private model: ILocationModel = {
    location: [],
    exactLocation: []
  }

  constructor(data: any) {
    try {
      if (isEmpty(data))
        return

      this.model.location = Array.from(data.location)
      this.model.exactLocation = Array.from(data.exact_location || data.exactLocation)
    } catch (e) {
      console.error(e)
    }
  }

  getFields() {
    return this.model
  }
}