import { ICarCheckpointModel } from './CarCheckpointAdapter'
import { CarCheckpointAdapter} from './CarCheckpointAdapter'
import QueryString from 'query-string'

export type QueryParams = {
  limit: number
  offset: number
  orderBy: string
  project: number
  search: string
  dateStart: string
  dateEnd: string
  statuses: string
  authors: string
  locations: string
}

export class GetCheckpointListQuery {
  query = ({
    limit,
    offset,
    orderBy,
    search,
    project,
    dateStart,
    dateEnd,
    authors,
    statuses,
    locations
  }: Partial<QueryParams>): string => {
    const queryString = QueryString.stringify({
      search,
      limit,
      offset,
      project,
      order_by: orderBy,
      access_date_start: dateStart,
      access_date_end: dateEnd,
      status: statuses,
      creator_id__in: authors,
      location__in: locations
    }, { skipNull: true })

    return `checkpoints/vehicles?${queryString}`
  }

  transformResponse = (response: { results: ICarCheckpointModel[], count: number }) => {
    const { results, count } = response

    const listOptions = results.map((user: ICarCheckpointModel) =>
      new CarCheckpointAdapter(user).getFields()
    )

    return { ...response, results: listOptions, count }
  }
}