import React from 'react'
import { IIcon } from '../../../models/IIcon'

const ImageEditor: React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  ...innerProps
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 19V5C21 3.9 20.1 3 19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19ZM8.5 13.5L11 16.51L14.5 12L19 18H5L8.5 13.5Z" fill="#5C5C5C" />
  </svg>
)

export default ImageEditor