import { ICarCheckpointModel, CarCheckpointAdapter } from './../../api/carCheckpointApi/CarCheckpointAdapter';
import { IUserCheckpointModel, UserCheckpointAdapter } from '../../api/userCheckpointApi/UserCheckpointAdapter';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoadingType } from '../utilityObject'

type InitialState = {
  passType: 'people' | 'vehicles'
  mode: 'edit' | 'fill'
  activePassId: number | null
  vehiclePassData: ICarCheckpointModel
  userPassData: IUserCheckpointModel
  needReload: boolean
  isExcelPassesCreationLoading: LoadingType
  errorUploadingPassesExcel: null | string
  passesListFromExcel: any[] | null
  isLoadingCreationMassPasses: LoadingType
}

const initialState: InitialState = {
  passType: 'vehicles',
  mode: 'fill',
  activePassId: null,
  vehiclePassData: new CarCheckpointAdapter({}).getFields(),
  userPassData: new UserCheckpointAdapter({}).getFields(),
  needReload: true,
  isExcelPassesCreationLoading: 'idle',
  errorUploadingPassesExcel: null,
  passesListFromExcel: null,
  isLoadingCreationMassPasses: 'idle',
}

const passActiveDataSlice = createSlice({
  name: 'createPassesSlice',
  initialState: initialState,
  reducers: {
    setMode: (state, { payload }: PayloadAction<InitialState['mode']>) => {
      state.mode = payload
    },
    setPassType: (state, { payload }: PayloadAction<InitialState['passType']>) => {
      state.passType = payload
    },
    setActivePassId: (state, { payload }: PayloadAction<InitialState['activePassId']>) => {
      state.activePassId = payload
    },
    setVehiclePassData: (state, { payload }: PayloadAction<InitialState['vehiclePassData']>) => {
      state.vehiclePassData = payload
    },
    setUserPassData: (state, { payload }: PayloadAction<InitialState['userPassData']>) => {
      state.userPassData = payload
    },
    setNeedReload: (state, { payload }: PayloadAction<boolean>) => {
      state.needReload = payload
    },
    setUploadingExcelError: (state, { payload }: PayloadAction<string | null>) => {
      state.errorUploadingPassesExcel = payload
    },
    setPassesListFromExcel: (state, { payload }: PayloadAction<any[] | null>) => {
      state.passesListFromExcel = payload
    },

    toggleLoadingSubscribers(state, action: PayloadAction<LoadingType>) {
      state.isExcelPassesCreationLoading = action.payload
    },
    toggleLoadingMassPassesCreation(state, action: PayloadAction<LoadingType>) {
      state.isLoadingCreationMassPasses = action.payload
    }
  }
})

export const passActiveDataActions = passActiveDataSlice.actions

export default passActiveDataSlice.reducer