import React from 'react'
import { IIcon } from '../../../models/IIcon'

const Logo: React.FC<IIcon> = ({
  width = '3',
  height = '2.1',
  color = '#5C5C5C',
  ...innerProps
}) => (
  <svg width={`${width}rem`} height={`${height}rem`} {...innerProps} viewBox="0 0 30 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.3139 0.482544V16.8129H29.8064L29.4042 19.6284H17.0693V0.482544H20.3139Z" fill="#1A65DA"/>
    <path d="M3.27142 13.7828C3.41444 15.0341 3.86135 15.9458 4.61217 16.5179C5.38087 17.0899 6.51603 17.376 8.01767 17.376C8.75061 17.376 9.36736 17.2955 9.8679 17.1346C10.3685 16.9737 10.7707 16.7592 11.0746 16.4911C11.3785 16.2229 11.593 15.9101 11.7181 15.5526C11.8433 15.1771 11.9058 14.7839 11.9058 14.3727C11.9058 14.0688 11.8611 13.7738 11.7718 13.4878C11.6824 13.1839 11.5215 12.9068 11.2891 12.6565C11.0567 12.4063 10.7439 12.1917 10.3506 12.013C9.95729 11.8163 9.45674 11.6644 8.84894 11.5571L6.40878 11.1013C5.44344 10.9225 4.58536 10.699 3.83454 10.4309C3.08372 10.1627 2.4491 9.81415 1.93068 9.38511C1.43013 8.9382 1.04578 8.4019 0.777634 7.77622C0.527361 7.13266 0.402224 6.35502 0.402224 5.44331C0.402224 4.53161 0.580991 3.7361 0.938524 3.05678C1.31393 2.37747 1.82342 1.81436 2.46698 1.36744C3.11054 0.902649 3.87029 0.562993 4.74625 0.348473C5.64008 0.116076 6.60542 -0.00012207 7.64226 -0.00012207C8.96513 -0.00012207 10.0735 0.151829 10.9673 0.455733C11.8611 0.759636 12.5852 1.16186 13.1393 1.66241C13.6935 2.14508 14.1136 2.70819 14.3996 3.35175C14.6857 3.99531 14.8823 4.66568 14.9896 5.36287L11.9327 5.84554C11.6824 4.77294 11.2444 3.97743 10.6187 3.45901C10.0109 2.94059 9.03664 2.68137 7.69589 2.68137C6.9272 2.68137 6.28364 2.75288 5.76522 2.89589C5.26467 3.02103 4.85351 3.20873 4.53173 3.45901C4.22783 3.6914 4.00437 3.96849 3.86135 4.29027C3.73622 4.59418 3.67365 4.91596 3.67365 5.25561C3.67365 6.09581 3.89711 6.72149 4.34402 7.13266C4.80882 7.54382 5.61327 7.8656 6.75737 8.098L9.41205 8.60748C11.4321 8.98289 12.9159 9.62645 13.8633 10.5382C14.8108 11.432 15.2845 12.728 15.2845 14.4263C15.2845 15.2308 15.1326 15.9816 14.8287 16.6788C14.5426 17.3581 14.0957 17.957 13.4879 18.4754C12.8801 18.9759 12.1204 19.3692 11.2087 19.6552C10.3148 19.9591 9.26904 20.1111 8.0713 20.1111C5.6222 20.1111 3.70046 19.6016 2.30609 18.5826C0.911708 17.5458 0.143013 15.9458 0 13.7828H3.27142Z" fill="black" fillOpacity="0.8"/>
  </svg>
)

export default Logo