import React from 'react'
import { IIcon } from '../../../models/IIcon'

const PersonWithKey: React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#949494',
  ...innerProps
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_20)">
    <path d="M7.5 8C9.433 8 11 6.433 11 4.5C11 2.567 9.433 1 7.5 1C5.567 1 4 2.567 4 4.5C4 6.433 5.567 8 7.5 8Z" fill="#5C5C5C"/>
    <path d="M7.5 10C4.99687 10 0 11.34 0 14L0 16H15V14C15 11.34 10.0031 10 7.5 10Z" fill="#5C5C5C"/>
    <g clipPath="url(#clip1_1_20)">
    <path d="M15.4926 4.41472C14.2787 4.18729 13.0006 4.73108 12.3481 5.86125C11.5206 7.29452 12.0129 9.13182 13.4462 9.95932C14.8794 10.7868 16.7167 10.2945 17.5442 8.86124C18.1967 7.73108 18.0286 6.35229 17.2247 5.41472L18.3122 3.53112L20.0442 4.53112L21.0442 2.79907L19.3122 1.79907L19.8122 0.933042L18.0801 -0.0669581L15.4926 4.41472ZM15.8122 7.86124C15.5372 8.33756 14.9225 8.50227 14.4462 8.22727C13.9698 7.95227 13.8051 7.33756 14.0801 6.86125C14.3551 6.38493 14.9698 6.22022 15.4462 6.49522C15.9225 6.77022 16.0872 7.38493 15.8122 7.86124Z" fill="#5C5C5C"/>
    </g>
    </g>
    <defs>
    <clipPath id="clip0_1_20">
    <rect width="21" height="16" fill="white"/>
    </clipPath>
    <clipPath id="clip1_1_20">
    <rect width="12" height="12" fill="white" transform="translate(8 7.39233) rotate(-60)"/>
    </clipPath>
    </defs>
  </svg>
)

export default PersonWithKey