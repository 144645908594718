import camelcaseFields from '../../utils/formatFields'

export interface IProjectUserModel {
  id: number
  firstName: string
  middleName: string
  lastName: string
  imageUrl?: string
  position: string
  companyId:number,
  companyType: string
  companyTitle: string
  roleInProject?:string
}

export class ProjectUserAdapter {
  private model: IProjectUserModel = {
    id: NaN,
    firstName: '',
    middleName: '',
    lastName: '',
    imageUrl: '',
    position: '',
    companyId: NaN,
    companyType: '',
    companyTitle: '',
    roleInProject: undefined
  }

  constructor(user: unknown) {
    try {
      const camelCaseUser = camelcaseFields(user)

      this.model.id = camelCaseUser.id
      this.model.firstName = camelCaseUser.profile.firstName
      this.model.middleName = camelCaseUser.profile.middleName
      this.model.lastName = camelCaseUser.profile.lastName
      this.model.position = camelCaseUser.profile.position
      this.model.imageUrl = camelCaseUser.profile.image?.url
      this.model.companyType = camelCaseUser.profile.company.type
      this.model.companyTitle = camelCaseUser.profile.company.title
      this.model.companyId = camelCaseUser.profile.company.id
      this.model.roleInProject = camelCaseUser.roleInProject

    } catch (e) {
      console.error(e)
    }
  }

  getFields(): IProjectUserModel {
    return this.model
  }
}