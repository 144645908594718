export interface IAttachmentModel {
  title: string
  attachmentId: number
  ownerId: number
  fileId: number
  fileUrl: string
}

export class AttachmentAdapter {
  private model: IAttachmentModel = {
    title: '',
    attachmentId: NaN,
    ownerId: NaN,
    fileId: NaN,
    fileUrl: ''
  }

  constructor(data: any) {
    try {
      this.model.title = data.title
      this.model.attachmentId = data.id
      this.model.ownerId = data.owner
      this.model.fileId = data.file.id
      this.model.fileUrl = data.file.url
    } catch (e) {
      console.error(e)
    }
  }

  getFields() {
    return this.model
  }
}