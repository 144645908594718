import React from 'react'
import { IIcon } from '../../../models/IIcon'

const CalendarToday: React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  ...innerProps
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.5 17C16.6046 17 17.5 16.1046 17.5 15C17.5 13.8954 16.6046 13 15.5 13C14.3954 13 13.5 13.8954 13.5 15C13.5 16.1046 14.3954 17 15.5 17Z" fill="#5C5C5C" />
    <path fillRule="evenodd" clipRule="evenodd" d="M6.5 3C4.84315 3 3.5 4.34315 3.5 6V18C3.5 19.6569 4.84315 21 6.5 21H18.5C20.1569 21 21.5 19.6569 21.5 18V6C21.5 4.34315 20.1569 3 18.5 3H6.5ZM5.5 18V7H19.5V18C19.5 18.5523 19.0523 19 18.5 19H6.5C5.94772 19 5.5 18.5523 5.5 18Z" fill="#5C5C5C" />
  </svg>
)
export default CalendarToday
