import { isEmpty } from 'lodash';
import { IProjectUserModel, ProjectUserAdapter } from '../projectUserApi/ProjectUserAdapter'

export interface IVisitorCheckpointModel extends IProjectUserModel {
  isEntered: boolean
}

export class VisitorCheckpointAdapter {
  private model: IVisitorCheckpointModel = {
    ...new ProjectUserAdapter({}).getFields(),
    isEntered: false
  }

  constructor(visitor: any) {
    try {
      if (isEmpty(visitor))
        return

      this.model = {
        ...new ProjectUserAdapter(visitor.user).getFields(),
        isEntered: visitor.isEntered
      }
    } catch {

    }
  }

  getFields() {
    return this.model
  }
}