import React, { useEffect, useRef, useState } from 'react'
import { PortalWrapper } from '../../../../../../../fsd/shared/ui-component'

import cn from 'classnames'
import './CardLinkTooltip.scss'

export const CardLinkTooltip:React.FC<PropsType> = ({
  cardLinkId,
  cardLinkTitle,
  isDisabledTooltip
}) => {
  const [top, setTop] = useState(0)
  const tooltipRef = useRef<HTMLDivElement | null>(null)

  const getPositionForTooltip = () => {
    const currentLink = document.getElementById(cardLinkId)
    const currentLinkRect = currentLink?.getBoundingClientRect()
    const currentLinkTop = currentLinkRect?.top

    //умножаем на 1.25 из transform: scale(0.8) на всем проекте
    setTop(currentLinkTop! * 1.25 + 6)
  }

  useEffect(() => {
    getPositionForTooltip()

    const resizeObserver = new ResizeObserver(getPositionForTooltip);
    resizeObserver.observe(document.querySelector('.nav-links')!);

    const navLinksWrapper = document.querySelector('.sidebar__nav-links')!
    navLinksWrapper.addEventListener('scroll', getPositionForTooltip)

    return () => {
      resizeObserver.disconnect()
      navLinksWrapper.removeEventListener('scroll',getPositionForTooltip)
    }
  }, [isDisabledTooltip])


  return (
    <PortalWrapper parentNodeSelector='.main-layout'>
      <div
        ref={tooltipRef}
        style={{ top: `${top}px`}}
        className={cn('left-sidebar-tooltip-hover-notification', { disable: isDisabledTooltip })}
      >
        {cardLinkTitle}
      </div>
    </PortalWrapper>
  )
}

type PropsType = {
  cardLinkId: string
  cardLinkTitle: string
  isDisabledTooltip: boolean
}