
export type ACCESS_SUBJECT_KEY = keyof typeof ACCESS_SUBJECT
export const ACCESS_SUBJECT = {
  createDocuments: {
    name: "Создание документов",
    height: '6.4'
  },
  templatesManagement: {
    name: "Управление шаблонами",
    height: '6.4'
  },
  readCheckpointLogs: {
    name: "Просмотр журнала посещений/проезда",
    height: '8.8'
  },
  readCheckpointDocuments: {
    name: "Проверка пропусков, контролер",
    height: '8.8'
  },
  createCheckpointDocuments: {
    name: "Редактирование и создание пропусков",
    height: '8.8'
  },
  createOrUpdateProject: {
    name: "Редактирование и удаление проекта",
    height: '8.8'
  },
  companiesManagement: {
    name: "Управление компаниями в проекте",
    height: '8.8'
  },
  rolesManagement: {
    name: "Управление ролевыми группами",
    height: '8.8'
  },
  userRolesManagement: {
    name: "Управление ролями пользователей",
    height: '8.8'
  },
  locationsManagement: {
    name: "Управление локациями",
    height: '6.4'
  },
  licensesManagement: {
    name: "Управление шаблонами удостоверений",
    height: '8.8'
  },
  readLicenses: {
    name: "Просмотр удостоверений",
    height: '6.4'
  },
  restrictedAccessToSystem: {
    name: "Ограничен доступ в систему",
    height: '8.8'
  }
}

export type ACCESS_SUBJECT_BY_MODULE_KEY = keyof typeof ACCESS_SUBJECT_BY_MODULE
export const ACCESS_SUBJECT_BY_MODULE = {
  docs: {
    title: 'Документы',
    height: '12.8',
    accessSubjects: [
      ACCESS_SUBJECT.createDocuments,
      ACCESS_SUBJECT.templatesManagement
    ],
    accessSubjectsKeys: [
      'createDocuments',
      'templatesManagement'
    ] as const
  },
  checkpoint: {
    title: 'КПП',
    height: '26.4',
    accessSubjects: [
      ACCESS_SUBJECT.readCheckpointLogs,
      ACCESS_SUBJECT.readCheckpointDocuments,
      ACCESS_SUBJECT.createCheckpointDocuments
    ],
    accessSubjectsKeys: [
      'readCheckpointLogs',
      'readCheckpointDocuments',
      'createCheckpointDocuments'
    ] as const
  },
  project: {
    title: 'Проект',
    height: '56.8',
    accessSubjects: [
      ACCESS_SUBJECT.createOrUpdateProject,
      ACCESS_SUBJECT.companiesManagement,
      ACCESS_SUBJECT.rolesManagement,
      ACCESS_SUBJECT.userRolesManagement,
      ACCESS_SUBJECT.locationsManagement,
      ACCESS_SUBJECT.licensesManagement,
      ACCESS_SUBJECT.readLicenses
    ],
    accessSubjectsKeys: [
      'createOrUpdateProject',
      'companiesManagement',
      'rolesManagement',
      'userRolesManagement',
      'locationsManagement',
      'licensesManagement',
      'readLicenses'
    ] as const
  },
  other: {
    title: 'system',
    height: '8.8',
    accessSubjects: [
      ACCESS_SUBJECT.restrictedAccessToSystem
    ],
    accessSubjectsKeys: [
      'restrictedAccessToSystem'
    ] as const
  }
}

export const sequenceKeyInMatrix: ACCESS_SUBJECT_KEY[] = [
  'createDocuments', 'templatesManagement',
  'readCheckpointLogs', 'readCheckpointDocuments', 'createCheckpointDocuments',
  'companiesManagement', 'rolesManagement', 'userRolesManagement', 'locationsManagement', 'licensesManagement', 'readLicenses', 'createOrUpdateProject',
  'restrictedAccessToSystem'
]
