import { VisitorCheckpointAdapter, IVisitorCheckpointModel } from './VisitorCheckpointAdapter'
import QueryString from 'query-string'

type QueryParams = {
  pk: number
  project: number
}

export class GetCheckpointByIdQuery {
  query = ({ pk, project }: QueryParams): string => {
    const queryString = QueryString.stringify({
      pk,
      project
    }, { skipNull: true })

    return `checkpoints/users?${queryString}`
  }

  transformResponse = (response: IVisitorCheckpointModel) => {
    return new VisitorCheckpointAdapter(response).getFields()
  }
} 