import React from 'react'
import { IIcon } from '../../../models/IIcon'

const BigBubble: React.FC<IIcon> = ({
  width = '18.7',
  height = '17.7',
  color = '#1A65DA',
  ...innerProps
}) => (
  <svg width={`${width}rem`} height={`${height}rem`} {...innerProps} viewBox="0 0 187 177" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect x="0.165039" y="11.6372" width="164.998" height="164.998" rx="82.4989" fill="#E8F1FB" />
    <path
      d="M103.335 49.7471C101.591 49.7471 99.8094 49.8987 98.0277 50.2778C87.5649 52.287 79.2249 60.7406 77.2537 71.2035C75.434 81.0977 79.0733 90.1958 85.6694 96.0717C87.2995 97.5123 88.1714 99.5214 88.1714 101.644V110.401C88.1714 114.571 91.5832 117.983 95.7532 117.983H96.8146C98.1415 120.258 100.53 121.774 103.335 121.774C106.14 121.774 108.566 120.258 109.855 117.983H110.917C115.087 117.983 118.499 114.571 118.499 110.401V101.644C118.499 99.5593 119.333 97.5123 120.925 96.1096C126.421 91.2573 129.871 84.1683 129.871 76.2833C129.871 61.6125 118.006 49.7471 103.335 49.7471ZM110.917 110.401H95.7532V106.61H110.917V110.401ZM110.917 102.819H95.7532V99.0286H110.917V102.819ZM115.883 90.4612C115.542 90.7645 115.276 91.1436 114.973 91.4468H91.659C91.3558 91.1057 91.0904 90.7266 90.7492 90.4233C85.7453 85.95 83.5086 79.2781 84.7217 72.6061C86.0864 65.2518 92.1518 59.1485 99.4683 57.7079C100.757 57.4426 102.046 57.3288 103.335 57.3288C113.798 57.3288 122.289 65.8204 122.289 76.2833C122.289 81.7043 119.977 86.8599 115.883 90.4612Z"
      fill="#1A65DA" />
    <path d="M105.23 80.0742H101.44V91.4469H105.23V80.0742Z" fill="#1A65DA" />
    <path d="M97.1918 72.0136L94.5113 74.6941L102.553 82.7357L105.233 80.0552L97.1918 72.0136Z" fill="#1A65DA" />
    <path d="M101.443 80.0939L104.124 82.7744L112.165 74.7328L109.485 72.0523L101.443 80.0939Z" fill="#1A65DA" />
    <rect x="20.835" y="1.36523" width="165" height="165" rx="82.5" stroke="#1A65DA" strokeWidth="2"
      strokeLinecap="round" strokeDasharray="10 15" />
  </svg>
)
export default BigBubble;