import React from 'react'
import { IIcon } from './IIcon'

export const TrashSimple: React.FC<IIcon> = ({
  width = '1.8',
  height = '1.8',
  color = '#949494',
  ...innerProps
}) => (
  <svg width={`${width}rem`} height={`${height}rem`} {...innerProps} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.75 3.9375C15.75 4.08668 15.6907 4.22976 15.5852 4.33525C15.4798 4.44074 15.3367 4.5 15.1875 4.5H14.625V14.625C14.625 14.9234 14.5065 15.2095 14.2955 15.4205C14.0845 15.6315 13.7984 15.75 13.5 15.75H4.5C4.20163 15.75 3.91548 15.6315 3.7045 15.4205C3.49353 15.2095 3.375 14.9234 3.375 14.625V4.5H2.8125C2.66332 4.5 2.52024 4.44074 2.41475 4.33525C2.30926 4.22976 2.25 4.08668 2.25 3.9375C2.25 3.78832 2.30926 3.64524 2.41475 3.53975C2.52024 3.43426 2.66332 3.375 2.8125 3.375H15.1875C15.3367 3.375 15.4798 3.43426 15.5852 3.53975C15.6907 3.64524 15.75 3.78832 15.75 3.9375ZM6.1875 2.25H11.8125C11.9617 2.25 12.1048 2.19074 12.2102 2.08525C12.3157 1.97976 12.375 1.83668 12.375 1.6875C12.375 1.53832 12.3157 1.39524 12.2102 1.28975C12.1048 1.18426 11.9617 1.125 11.8125 1.125H6.1875C6.03832 1.125 5.89524 1.18426 5.78975 1.28975C5.68426 1.39524 5.625 1.53832 5.625 1.6875C5.625 1.83668 5.68426 1.97976 5.78975 2.08525C5.89524 2.19074 6.03832 2.25 6.1875 2.25Z" fill="#949494" />
  </svg>
)