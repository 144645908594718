import React from 'react'
import { IIcon } from '../../../models/IIcon'

const UnderlineEditor: React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  ...innerProps
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 17C15.31 17 18 14.31 18 11V3H15.5V11C15.5 12.93 13.93 14.5 12 14.5C10.07 14.5 8.5 12.93 8.5 11V3H6V11C6 14.31 8.69 17 12 17ZM5 19V21H19V19H5Z" fill="#5C5C5C" />
  </svg>
)
export default UnderlineEditor