import React from 'react'
import { IIcon } from '../../../models/IIcon'

const CheckNew: React.FC<IIcon> = ({
    width = '2.4',
    height = '2.4',
    color = '#949494',
    ...innerProps
}) => (
    <svg width={`${width}rem`} height={`${height}rem`} {...innerProps} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_7788_5369)">
            <path d="M9.00016 16.1701L4.83016 12.0001L3.41016 13.4101L9.00016 19.0001L21.0002 7.00009L19.5902 5.59009L9.00016 16.1701Z" fill={color} />
        </g>
        <defs>
            <clipPath id="clip0_7788_5369">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>

)

export default CheckNew