import React from 'react'
import * as Icons from '../../../../../../UI/icons'

export const PrefixIcon = React.memo<PropsType>(({
  isActive,
  isOpenLeftSidebar,
  disabledSidebar
}) => (
  disabledSidebar
    ? <Icons.Plus className='project' />
    : <Icons.Project className='project' color={isActive && isOpenLeftSidebar ? '#1A65DA' : '#5C5C5C'} />

))

type PropsType = {
  disabledSidebar: boolean
  isActive: boolean
  isOpenLeftSidebar: boolean
}