import React from 'react'

const QrCode = ({ width = '1.8', height = '1.8', color = '#1A65DA', opacity = '0.64', className = '', onClick = () => { }, ...innerProps }) => (
    <div className={className} onClick={onClick}>
        <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 0H18V6H16V2H12V0ZM18 18V12H16V16H12V18H18ZM6 0V2H2V6H0V0H6ZM2 12H0V18H6V16H2V12ZM14 4H10V8H14V4ZM14 10H10V14H14V10ZM4 4H8V8H4V4ZM8 10H4V14H8V10Z" fill={color} />
        </svg>
    </div>
)
export default QrCode
