import config from '../config.js'

export const setToken = token => localStorage.setItem(config.token, JSON.stringify(token))

export const setTenantInfo = info => localStorage.setItem('tenant', JSON.stringify(info))
export const removeTenantInfo = () => localStorage.removeItem('tenant')
export const getTenantInfo = () => (
  localStorage.getItem('tenant')
    ? JSON.parse(localStorage.getItem('tenant'))
    : null
)

export const setCurrentDomain = domain => localStorage.setItem('current-domain', JSON.stringify(domain))
export const getCurrentDomain = () => {
  const domain = localStorage.getItem('current-domain')
  return domain && JSON.parse(domain)
}

export const getToken = () => JSON.parse(localStorage.getItem(config.token))?.access
export const getRefresh = () => JSON.parse(localStorage.getItem(config.token))?.refresh

export const getExpirationTime = () => (
  JSON.parse(atob(getToken().split('.')[1])).exp
)

export const removeToken = () => localStorage.removeItem(config.token)

export const setProject = (id) => localStorage.setItem('project-id', id)

export const getProjectId = () => localStorage.getItem('project-id')

export const setProjectExtended = (data) => {
  const storagedState = JSON.parse(localStorage.getItem('project-id-extended'))
  if (storagedState) {
    const userProject = storagedState.find(proj => proj.userId === data.userId)
    if (userProject) {
      userProject.projectId = data.projectId
      storagedState[storagedState.indexOf(userProject)] = userProject
    }
    else {
      storagedState.push({ projectId: data.projectId, userId: data.userId, tenantId: null })
    }
    localStorage.setItem('project-id-extended', JSON.stringify(storagedState))
  }
  else if (data.projectId && data.userId) {
    localStorage.setItem('project-id-extended', JSON.stringify([{ projectId: data.projectId, userId: data.userId, tenantId: null }]))
  }
}

export const getProjectIdExtended = (userId) => {
  const storagedState = JSON.parse(localStorage.getItem('project-id-extended'))
  const userProject = storagedState?.find(proj => proj.userId === userId)
  return userProject ? userProject.projectId : null
}

export const setStateLeftSidebar = (value) => localStorage.setItem('left-sidebar', value)
export const getStateLeftSidebar = () => localStorage.getItem('left-sidebar')
export const removeStateLeftSidebar = () => localStorage.removeItem('left-sidebar')

export const loadState = () => {
  try {
    const state = localStorage.getItem('state')
    return state ? JSON.parse(state) : {}
  } catch (err) {
    console.err(err)
    return {}
  }
}

export const saveState = state => {
  try {
    localStorage.setItem('state', JSON.stringify(state))
  } catch { }
}


export const clearLocalStorage = () => {
  localStorage.removeItem(config.token)
  localStorage.removeItem('project-id')
  removeTenantInfo()
}
