import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../../../../(deprecated)/api/BaseQuery'
import { GetCheckpointVehiclesLogs } from './GetCheckpointVehiclesLogs'
import { GetCheckpointPeopleLogs } from './GetCheckpointPeopleLogs'

export const checkpointLogsApi = createApi({
  reducerPath: 'checkpointLogsApi',
  baseQuery: baseQuery(),
  endpoints: (builder) => ({
    getVehiclesList: builder.query(new GetCheckpointVehiclesLogs()),
    getPeopleList: builder.query(new GetCheckpointPeopleLogs())
  }),
})