import React from 'react'

const DownloadIcon = ({ width = '2.4', height = '2.4', color = 'black', opacity = '0.64', className='', ...innerProps }) => (
  <div className={className}>
    <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 9.00049H15V3.00049H9V9.00049H5L12 16.0005L19 9.00049ZM5 18.0005V20.0005H19V18.0005H5Z" fill={color} fillOpacity={opacity} />
    </svg>
  </div>

)

export default DownloadIcon
