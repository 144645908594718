import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ICheckpointLogModel } from '../../rtk/CheckpointLogs/checkpointLogsAdapter'

export type CheckpointLogsTabsType = 'vehicles' | 'people'
export type CheckpointLogsTabType = {
  title: 'Автомобили' | 'Люди'
  path: CheckpointLogsTabsType
}

const initialState = {
  activeTab: 'vehicles' as CheckpointLogsTabsType,
  search: '',
  sortOrder: 'asc' as 'asc' | 'desc',
  totalCount: null as number | null,
  isNotFound: false,
  offset: 0,
  entites: null as ICheckpointLogModel[] | null
}

const checkpointLogsSlice = createSlice({
  name: 'checkpoint-logs',
  initialState,
  reducers: {
    setSearchString(state, { payload }: PayloadAction<string>) {
      state.search = payload
      state.entites = []
      state.offset = 0
      state.isNotFound = false
      state.totalCount = null
    },

    setActiveTab(state, { payload }: PayloadAction<CheckpointLogsTabsType>) {
      if (state.activeTab === payload)
        return

      state.activeTab = payload
      state.entites = []
      state.offset = 0
      state.search = ''
      state.isNotFound = false
      state.totalCount = null
    },

    toggleSortOrder(state, { payload }: PayloadAction<'asc' | 'desc'>) {
      state.sortOrder = payload
      state.entites = []
      state.offset = 0
    },

    setCheckpointEntites(state, { payload }: PayloadAction<ICheckpointLogModel[]>) {

      if (payload.length === 0) {
        state.isNotFound = true
        return
      }

      state.entites = state.entites ? [...state.entites, ...payload] : [...payload]
      state.offset = state.offset + 20
      state.isNotFound = false
    },

    setTotalCount(state, { payload }: PayloadAction<number>) {
      state.totalCount = payload
    },

    setOffset(state, { payload }: PayloadAction<number>) {
      state.offset = payload
    },

    resetCheckpointLogs(state) {
      state.activeTab = 'vehicles'
      state.search = ''
      state.sortOrder = 'asc'
      state.totalCount = null
      state.entites = []
      state.offset = 0
      state.isNotFound = false
    }

  }
})

export const {
  setSearchString,
  setActiveTab,
  toggleSortOrder,
  setCheckpointEntites,
  setTotalCount,
  resetCheckpointLogs,
  setOffset

} = checkpointLogsSlice.actions

export default checkpointLogsSlice.reducer