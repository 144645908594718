import React from 'react'
import { IIcon } from '../../../models/IIcon'

const SchemaIcon: React.FC<IIcon> = ({ 
  width = '2.4', 
  height = '2.4', 
  color = 'white', 
  ...innerProps 
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 8V0H13V3H7V0H0V8H7V5H9V15H13V18H20V10H13V13H11V5H13V8H20Z" fill="#5C5C5C"/>
  </svg>
)

export default SchemaIcon