import { baseQuery } from '../BaseQuery'
import { createApi } from '@reduxjs/toolkit/query/react'
import { GetCheckpointListQuery } from './GetCheckpointListQuery'
import { PostCheckpointNewQuery } from './PostCheckpointNewQuery'
import { PutCheckpointByIdQuery } from './PutCheckpointByIdQuery'
import { GetCheckpointByIdQuery } from './GetCheckpointByIdQuery'

export const userCheckpointsApi = createApi({
  reducerPath: 'userCheckpointsApi',
  baseQuery: baseQuery(),
  endpoints: (builder) => ({
    getList: builder.query(new GetCheckpointListQuery()),
    getById: builder.query(new GetCheckpointByIdQuery()),
    postItem: builder.mutation(new PostCheckpointNewQuery()),
    updateItem: builder.mutation(new PutCheckpointByIdQuery())
  })
})