import React from 'react'
import { NavLink } from 'react-router-dom'

export const CardLinkWrapper:React.FC<PropsType> = ({
  condition,
  children,
  ...props
}) => React.createElement(condition ? 'div' : NavLink, props, children)

type PropsType = {
  condition: boolean
  children: React.ReactNode
  id: string
  to: string
  className: string
  onMouseEnter: (e: React.MouseEvent<HTMLElement>) => void
  onMouseLeave: (e: React.MouseEvent<HTMLElement>) => void
}