import { CarCheckpointAdapter, ICarCheckpointModel } from "./CarCheckpointAdapter"
import QueryString from 'query-string'

type QueryParams = {
  pk: number
  project: number
}

export class GetCheckpointByIdQuery {
  query = ({ pk, project }: QueryParams): string => {
    const queryString = QueryString.stringify({
      pk,
      project
    }, { skipNull: true })

    return `checkpoints/vehicles?${queryString}`
  }

  transformResponse = (response: ICarCheckpointModel) => {
    return new CarCheckpointAdapter(response).getFields()
  }
} 