import { getTenantInfo } from '../localStorageManagement'
import { getRequestUrl } from '../getRequestUrl'


type DevelopmentHostConfigKey = keyof typeof developmentHostConfig

const reviewKeys: DevelopmentHostConfigKey[] = ['egor', 'emil', 'victor', 'artem', 'evgen']

const developmentHostConfig = {
  uat: 'uat.simple-solution.liis.su',
  prodDev: 'simple-solution.liis.su',
  emil: 'emilyanbaev.test.simple-solution.liis.su',
  artem: 'arteeemponomarev.test.simple-solution.liis.su',
  egor: 'meliciron.test.simple-solution.liis.su',
  victor: 'victortrumpel.test.simple-solution.liis.su',
  evgen: 'evgeny05.test.simple-solution.liis.su'
}

class Host {
  private static _instance: Host = new Host()
  private _requestUrl: string = ''
  private _websocketRequestUrl: string = ''
  public isLocalDevelopmentWithReviewApi: boolean = false

  //SETTINGS
  public apiVersion: string = '/v2'
  private _developmentHostKey: DevelopmentHostConfigKey =
    (process.env.REACT_APP_HOST as DevelopmentHostConfigKey) ?? 'uat'

  constructor() {
    if (Host._instance) {
      throw new Error("Ошибка создания экземпляра: используйте Host.getInstance() вместо нового.")
    }
    Host._instance = this
  }

  public static getInstance(): Host {
    return Host._instance
  }
  public getRequestUrl() {
    return this._requestUrl
  }
  public getWebsocketRequestUrl() {
    return this._websocketRequestUrl
  }
  public updateHost() {
    const tenant = getTenantInfo()

    //PRODUCTION STAND
    if (process.env.NODE_ENV == 'production' && process.env?.REACT_APP_LOCAL !== 'true') {
      this._requestUrl = `${window.location.origin}/api${this.apiVersion}`
      this._websocketRequestUrl = `wss://${window.location.origin.replace('https://', '')}/ws${this.apiVersion}`
      return
    }

    //REVIEW STAND
    if (process.env?.REACT_APP_LOCAL === 'true') {
      this._requestUrl = window.location.origin + this.apiVersion
      this._websocketRequestUrl = `wss://${window.location.origin.replace('https://', '')}/ws${this.apiVersion}`
      return
    }

    //DEVELOPMENT

    //Если мы разрабатываем локально и при этом подключаемся к апишке ревью
    //Сделано для того, чтобы в checkTenantInUrl убрать localhost
    if (reviewKeys.includes(this._developmentHostKey))
      this.isLocalDevelopmentWithReviewApi = true

    const host = developmentHostConfig[this._developmentHostKey]

    this._requestUrl = getRequestUrl(tenant, `https://${host}/api${this.apiVersion}`)
    this._websocketRequestUrl = getRequestUrl(tenant, `wss://${host}/ws${this.apiVersion}`)
  }
}

//Получение первоначального хоста
const HOST: Host = Host.getInstance()
HOST.updateHost()

export default Host