import React from 'react'

const CircleChevron = ({ width = '3.0', height = '3.0', color = 'white', ...innerProps }) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.781 20.6569L19.4379 15L13.781 9.34319L11.8954 11.2288L15.6666 15L11.8954 18.7713L13.781 20.6569Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M15 29.6667C6.8998 29.6667 0.333313 23.1003 0.333313 15.0001C0.333313 6.89991 6.8998 0.333416 15 0.333416C23.1002 0.333416 29.6666 6.89991 29.6666 15.0001C29.6666 23.1003 23.1002 29.6667 15 29.6667ZM27 15.0001C27 21.6275 21.6274 27.0001 15 27.0001C8.37256 27.0001 2.99998 21.6275 2.99998 15.0001C2.99998 8.37267 8.37256 3.00008 15 3.00008C21.6274 3.00008 27 8.37267 27 15.0001Z" fill='transparent' />
  </svg>

)

export default CircleChevron
