import React from 'react'
import { useTSelector } from '../../../utils/hooks/reduxHooks'
import { LeftSidebarHeader } from './LeftSidebarHeader/LeftSidebarHeader'
import { LeftSidebarProject } from './LeftSidebarProject/LeftSidebarProject'
import { LeftSidebarProfile } from './LeftSidebarProfile/LeftSidebarProfile'
import { LeftSidebarSupport } from './LeftSidebarSupport/LeftSidebarSupport'
import { LeftSidebarNavLinks } from './LeftSidebarNavLinks/LeftSidebarNavLinks'

import cn from 'classnames'
import './LeftSidebar.scss'

export const LeftSidebar = () => {

  const isOpenLeftSidebar = useTSelector(state => state.app.isOpenedLeftSidebar)

  return (
    <div
      id='left-sidebar'
      className={cn('left-sidebar', {
        sidebar_open: isOpenLeftSidebar,
      })}
    >
      <LeftSidebarHeader />
      <LeftSidebarNavLinks />

      <div className='bottom-buttons'>
        <LeftSidebarProject />
        <LeftSidebarProfile />
        <LeftSidebarSupport />
      </div>
    </div>
  )
}

type LeftSidebarProps = {
  disabledSidebar: boolean
}