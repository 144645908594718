import { EntityOption } from '../../components/UI/InputEntityTags/InputEntityTags'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DocsFilter, MultiplyFilterKeys } from './model'

export const initialState: Readonly<DocsFilter> = {
  search: {
    searchValue: '',
    searchType: 'title'
  },
  templates: [],
  owners: [],
  companies: [],
  statuses: [],
  dateTime: ''
}

export const docsFilterSlice = createSlice({
  name: 'docsFilter',
  initialState,
  reducers: {
    setMultiplyFilterProperty(state, { payload }: PayloadAction<{
      filterKey: MultiplyFilterKeys,
      filterValue: EntityOption[]
    }>) {
      const { filterKey, filterValue } = payload
      state[filterKey] = filterValue
    },

    setDateTimeFilter(state, { payload }: PayloadAction<string>) {
      state.dateTime = payload
    },

    setSearchFilter(state, { payload }: PayloadAction<DocsFilter['search']>) {
      state.search = payload
    },

    setFilters(state, { payload }: PayloadAction<Partial<DocsFilter>>) {
      (Object.keys(payload) as (keyof DocsFilter)[]).map((key) => (
        (state as Record<string, unknown>)[key] = payload[key]
      ))
    },

    clearFilter(
      state,
      { payload = Object.keys(initialState) as (keyof DocsFilter)[] }: PayloadAction<(keyof DocsFilter)[] | undefined>
    ) {
      const keysNeedToClear = payload
      Object.entries(initialState).forEach(([stateKey, filterValue]) => {
        const key = stateKey as keyof DocsFilter
        if (!keysNeedToClear.includes(key)) return
        (state as Record<string, unknown>)[stateKey] = filterValue
      })
    },

    resetDocFilters() {
      return initialState
    },

  }
})

export const {
  setMultiplyFilterProperty,
  clearFilter,
  setDateTimeFilter,
  setSearchFilter,
  setFilters,
  resetDocFilters,
} = docsFilterSlice.actions

export const docsFiltersKeys = Object.keys(initialState)

export default docsFilterSlice.reducer