import {
  SET_LOADING_GET_ALL_COMPANIES,
  GET_ALL_COMPANIES_SUCCESS,
  GET_ALL_COMPANIES_FAILED,
  SET_LOADING_POST_NEW_COMPANY,
  POST_NEW_COMPANY_SUCCESS,
  POST_NEW_COMPANY_FAILED,
  SET_LOADING_GET_ACTIVE_COMPANY_WORKER,
  GET_ACTIVE_COMPANY_WORKER_SUCCESS,
  GET_ACTIVE_COMPANY_WORKER_FAILED,
  GET_ACTIVE_COMPANY_ACTIVE_USER_SUCCESS,
  GET_ACTIVE_COMPANY_ACTIVE_USER_FAILED,
  SET_LOADING_GET_ACTIVE_COMPANY_ACTIVE_USER,
  POST_TOGGLE_LOADING_BY_INN,
  POST_COMPANY_BY_INN_SUCCESS,
  POST_COMPANY_BY_INN_FAILED,
  CLEAR_COMPANY_BY_INN,
  GET_LINK_FOR_TEMPLATE_SUCCESS,
  GET_TOGGLE_LOADING_LINK_TEMPLATE,
  DELETE_COMPANY_IN_ALLCOMPANIES,
  POST_COMPANY_BY_INN_RESET,
  CLEAR_ALL_COMPANIES_INFO,
  SET_HAS_BEEN_FETCHED,
  SET_LOADING_UPLOAD_COMPANY_FROM_EXCEL,
} from './constants'

const initialState = {
  companies: [],
  companiesCount: 0,
  isLoadingCompanies: false,
  isLoadingPostCompany: false,
  isLoadingActiveCompanyActiveUser: false,
  isLoadingActiveCompanyWorker: false,
  isLoadingUploadingCompanyFromExcel: false,
  count: 0,
  companyByInn: {},
  isLoadingCompanyByInn: false,
  isFailedSearchCompanyByInn: false,
  linkForTempalte: null,
  isLoadingLinkForTempalte: false,
  hasBeenFetched: false,
  totalPages: null
}

export const allCompaniesReducer = (state = initialState, { type, payload }) => {
  switch (type) {

    case SET_LOADING_GET_ALL_COMPANIES:
      return { ...state, isLoadingCompanies: true }

    case SET_HAS_BEEN_FETCHED:
      return { ...state, hasBeenFetched: payload }

    case GET_ALL_COMPANIES_SUCCESS:
      const companies = payload.hasOwnProperty('results')
        ? payload.results
        : payload
      return {
        ...state,
        isLoadingCompanies: false,
        companies: [...state.companies, ...companies],
        companiesCount: payload.count,
        totalPages: payload?.meta?.totalPages
      }
    case CLEAR_ALL_COMPANIES_INFO:
      return {
        ...state,
        companies: [],
        companiesCount: 0
      }

    case GET_ALL_COMPANIES_FAILED:
      return {
        ...state,
        isLoadingCompanies: false
      }

    case SET_LOADING_POST_NEW_COMPANY:
      return {
        ...state,
        isLoadingPostCompany: true
      }

    case POST_NEW_COMPANY_SUCCESS:
      return {
        ...state,
        isLoadingPostCompany: false,
      }

    case POST_NEW_COMPANY_FAILED:
      return {
        ...state,
        isLoadingPostCompany: false
      }

    case SET_LOADING_GET_ACTIVE_COMPANY_WORKER:
      return {
        ...state,
        isLoadingActiveCompanyWorker: true
      }

    case GET_ACTIVE_COMPANY_WORKER_SUCCESS:
      const indexActiveCompany = state.companies.findIndex(({ id }) => id === payload.companyId)
      const currentCompanis = [...state.companies]
      currentCompanis[indexActiveCompany].worker = currentCompanis[indexActiveCompany].worker
        ? { users: [...currentCompanis[indexActiveCompany].worker.users, ...payload.data.users] }
        : { users: payload.data.users }
      // По идее count теперь летит отдельным полем. Судя по всему, этот запрос сейчас никак не используется,
      // но на всякий случай добавил OR.
      currentCompanis[indexActiveCompany].worker.count = payload.data.meta.totalResults || payload.count
      return {
        ...state,
        isLoadingActiveCompanyWorker: false,
        companies: currentCompanis,
      }

    case GET_ACTIVE_COMPANY_WORKER_FAILED:
      return {
        ...state,
        isLoadingActiveCompanyWorker: false
      }

    case SET_LOADING_GET_ACTIVE_COMPANY_ACTIVE_USER:
      return {
        ...state,
        isLoadingActiveCompanyActiveUser: true
      }

    case GET_ACTIVE_COMPANY_ACTIVE_USER_SUCCESS:
      const indexCompany = state.companies.findIndex(({ id }) => id === payload.companyId)
      const newCompanies = [...state.companies]
      newCompanies[indexCompany].activeUser = newCompanies[indexCompany].activeUser
        ? { users: [...newCompanies[indexCompany].activeUser.users, ...payload.data.users] }
        : { users: payload.data.users }
      newCompanies[indexCompany].activeUser.count = payload.data.meta.totalResults
      return {
        ...state,
        isLoadingActiveCompanyActiveUser: false,
        companies: newCompanies,
      }

    case GET_ACTIVE_COMPANY_ACTIVE_USER_FAILED:
      return {
        ...state,
        isLoadingActiveCompanyActiveUser: false,
      }
    case POST_TOGGLE_LOADING_BY_INN:
      return { ...state, isLoadingCompanyByInn: !state.isLoadingCompanyByInn }
    case POST_COMPANY_BY_INN_SUCCESS:
      return {
        ...state,
        companyByInn: { ...payload, title: payload.companyName, image: {}, userEmail: [''] },
        isFailedSearchCompanyByInn: false
      }
    case POST_COMPANY_BY_INN_FAILED:
      return { ...state, isFailedSearchCompanyByInn: true }
    case POST_COMPANY_BY_INN_RESET:
      return { ...state, isFailedSearchCompanyByInn: false }
    case CLEAR_COMPANY_BY_INN:
      return { ...state, companyByInn: {} }

    case GET_LINK_FOR_TEMPLATE_SUCCESS:
      return { ...state, linkForTempalte: payload }
    case GET_TOGGLE_LOADING_LINK_TEMPLATE:
      return { ...state, isLoadingLinkForTempalte: !state.isLoadingLinkForTempalte }
    case DELETE_COMPANY_IN_ALLCOMPANIES:
      return {
        ...state,
        companies: state.companies.filter(({ id }) => id !== Number(payload))
      }

    case SET_LOADING_UPLOAD_COMPANY_FROM_EXCEL: 
      return {
        ...state,
        isLoadingUploadingCompanyFromExcel: payload
      }  
    
    default: return state
  }
}