import React, { CSSProperties } from 'react'
import { IIcon } from '../../../models/IIcon'

interface ITickIcon extends IIcon {
  style?: CSSProperties
}

const Tick: React.FC<ITickIcon> = ({
  width = '1.4',
  height = '1.4',
  color = '#000000',
  ...innerProps
}) => (
  <svg {...innerProps}  width={`${width}rem`} height={`${height}rem`} viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.75 8.12738L1.62249 4.99988L0.557495 6.05738L4.75 10.2499L13.75 1.24988L12.6925 0.192383L4.75 8.12738Z" fill={color}/>
  </svg>
)
export default Tick