import React from 'react'
import { IIcon } from '../../../models/IIcon'

const StagesDocument:React.FC<IIcon> = ({
    width = '2.2',
    height = '2.2',
    color = '#5C5C5C',
    ...innerProps
}) => (
    <svg  width={`${width}rem`} height={`${height}rem`} {...innerProps} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.4993 10.0833C17.0056 10.0833 17.416 10.4937 17.416 11C17.416 11.5063 17.0056 11.9167 16.4993 11.9167H9.16602C8.65975 11.9167 8.24935 11.5063 8.24935 11C8.24935 10.4937 8.65976 10.0833 9.16602 10.0833H16.4993Z" fill={color} />
        <path d="M16.4993 13.75C17.0056 13.75 17.416 14.1604 17.416 14.6667C17.416 15.1729 17.0056 15.5833 16.4993 15.5833H9.16602C8.65975 15.5833 8.24935 15.1729 8.24935 14.6667C8.24935 14.1604 8.65976 13.75 9.16602 13.75H16.4993Z" fill={color} />
        <path d="M19 8C19 7.44772 18.5523 7 18 7H6C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H18C18.5523 9 19 8.55228 19 8Z" fill={color} />
    </svg>

)

export default StagesDocument