import { ILocationModel, LocationAdapter } from './../LocationAdapter';
import { isEmpty, isNumber, isString } from 'lodash'
import { IAttachmentModel, AttachmentAdapter } from './../AttachmentAdapter'

export interface IUserCheckpointModel {
  id: number
  accessDateStart: string
  accessDateEnd: string
  project: number
  comment: string
  guest?: string
  registeredVisitor: number
  contactUser: string
  contactPhone: string
  location: ILocationModel
  attachments: IAttachmentModel[]
  status: string
  creatorId: number
  creatorFirstName: string
  creatorLastName: string
  creatorMiddleName: string
  visitorFullName: string
  visitorPosition: string
  visitorCompanyType: string
  visitorCompanyTitile: string
}

export class UserCheckpointAdapter {
  private model: IUserCheckpointModel = {
    id: NaN,
    accessDateEnd: '',
    accessDateStart: '',
    attachments: [],
    comment: '',
    project: NaN,
    status: '',
    guest: '',
    contactUser: '',
    registeredVisitor: NaN,
    contactPhone: '',
    location: new LocationAdapter({}).getFields(),
    creatorId: NaN,
    creatorFirstName: '',
    creatorLastName: '',
    creatorMiddleName: '',
    visitorFullName: '',
    visitorPosition: '',
    visitorCompanyType: '',
    visitorCompanyTitile: ''
  }

  constructor(data: any) {
    try {
      if (isEmpty(data))
        return

      if (isNumber(data.id))
        this.model.id = data.id
      if (isString(data.access_date_start))
        this.model.accessDateStart = data.access_date_start
      if (isString(data.access_date_end))
        this.model.accessDateEnd = data.access_date_end
      if (isNumber( data.project))
        this.model.project = data.project
      if (isString(data.comment))
        this.model.comment = data.comment
      if (isString(data.guest))
        this.model.guest = data.guest
      if (isString(data.contact_user))
        this.model.contactUser = data.contact_user
      if (isString(data.contact_phone))
        this.model.contactPhone = data.contact_phone

      this.model.location = new LocationAdapter(data.location).getFields()

      this.model.attachments = data.attachments
        ? data.attachments?.map((entity: any) => new AttachmentAdapter(entity).getFields())
        : []

      const creatorProfile = data?.creator?.profile

      if (isNumber(data?.creator?.id))
        this.model.creatorId = data.creator.id

      if (data?.creator?.profile) {
        if (isString(creatorProfile.first_name))
          this.model.creatorFirstName = creatorProfile.first_name
        if (isString(creatorProfile.last_name))
          this.model.creatorLastName = creatorProfile.last_name
        if (isString(creatorProfile.middle_name))
          this.model.creatorMiddleName = creatorProfile.middle_name
      }

      if (data.visitor.full_name)
        this.model.guest = data.visitor.full_name

      const visitorProfile = data.visitor.profile

      if (visitorProfile) {
        this.model.visitorFullName = 
          `${visitorProfile.last_name} ${visitorProfile.first_name} ${visitorProfile.middle_name}`
      
        if (isNumber(data.visitor.id))
          this.model.registeredVisitor = data.visitor.id
        if (isString(visitorProfile.position))
          this.model.visitorPosition = visitorProfile.position
      }

      const visitorCompany = data?.visitor?.profile?.company
        
      if (visitorCompany) {
        if (isString(visitorCompany.type))
          this.model.visitorCompanyType = visitorCompany.type
        if (isString(visitorCompany.title))
          this.model.visitorCompanyTitile = visitorCompany.title
      }

      if (isString(data.status))
        this.model.status = data.status
    } catch (error) {
      console.error(error)
    }
  }

  getFields() {
    return this.model
  }
}