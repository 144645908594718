import React from 'react'
import { IIcon } from '../../../models/IIcon'

export const Phone: React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#949494',
  ...innerProps
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
    <path d="M21.7388 16.4138C21.5716 17.6841 20.9477 18.8502 19.9837 19.6942C19.0196 20.5382 17.7813 21.0023 16.5 21.0001C9.05626 21.0001 3.00001 14.9438 3.00001 7.50005C2.99771 6.21876 3.4619 4.98045 4.30588 4.01639C5.14986 3.05234 6.31592 2.42847 7.58626 2.2613C7.9075 2.22208 8.2328 2.2878 8.51362 2.44865C8.79444 2.60951 9.0157 2.85686 9.14438 3.1538L11.1244 7.57412V7.58537C11.2229 7.81267 11.2636 8.06083 11.2428 8.30769C11.222 8.55455 11.1404 8.79242 11.0053 9.00005C10.9884 9.02537 10.9706 9.0488 10.9519 9.07224L9.00001 11.386C9.7022 12.8129 11.1947 14.2922 12.6403 14.9963L14.9222 13.0547C14.9446 13.0359 14.9681 13.0184 14.9925 13.0022C15.2 12.8639 15.4387 12.7794 15.687 12.7565C15.9353 12.7336 16.1854 12.7729 16.4147 12.871L16.4269 12.8766L20.8434 14.8557C21.1409 14.9839 21.3889 15.205 21.5503 15.4858C21.7116 15.7667 21.7778 16.0922 21.7388 16.4138Z" fill="#5C5C5C"/>
  </svg>
)