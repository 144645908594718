import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTSelector } from '../../../../utils/hooks/reduxHooks'
import { useLinks } from './hooks/useLinks'
import { CardLink } from './CardLink/CardLink'
import { channelType } from '../../../pages/Chat/type'
import { IProject } from '../../../../models/IProject'

import cn from 'classnames'
import './LeftSidebarNavLinks.scss'

const AMOUNT_OF_PIXELS_FOR_SCROLL_BORDER = 16

export const LeftSidebarNavLinks = () => {
  const [isScrolled, setIsScrolled] = useState(false)

  const currentLinks = useLinks()
  const { pathname: currentPathUrl } = useLocation()

  const openChannels = useTSelector((state: any) => state.chat.openChannels) as channelType[]
  const projectList = useTSelector((state:any) => state.project.projectList) as IProject[]
  const finishGetProject = useTSelector((state: any) => state.project.finishGetProject)
  const disabledLeftSideBar = !projectList.length && finishGetProject

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    if (isScrolled) {
      e.currentTarget.scrollTop < AMOUNT_OF_PIXELS_FOR_SCROLL_BORDER &&
      setIsScrolled(false)
    }
    e.currentTarget.scrollTop > AMOUNT_OF_PIXELS_FOR_SCROLL_BORDER && setIsScrolled(true)
  }

  return (
    <div
      className={cn('sidebar__nav-links', {
        'sidebar__nav-links__scrolling': isScrolled
      })}
      onScroll={handleScroll}
    >
      <ul className='nav-links'>
        {currentLinks?.map(link => (
          <CardLink
            key={link.title}
            dataLink={link}
            currentPathUrl={currentPathUrl}
            withCount={link.pathUrl == '/chat' ? openChannels.reduce((accum, channel) => accum + channel.unreadMessagesCount, 0) : undefined}
            disabledSidebar={disabledLeftSideBar}
          />))}
      </ul>
    </div>
  )
}