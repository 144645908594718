import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../BaseQuery'
import { GetLocationBranchQuery } from './GetLocationBranchQuery'

export const locationTreeApi = createApi({
  reducerPath: 'locationTreeApi',
  baseQuery: baseQuery(),
  endpoints: (builder) => ({
    getBranch: builder.query(new GetLocationBranchQuery())
  })
})