import { Action, Store } from 'redux'
import { getToken } from '../../localStorageManagement'
import camelcaseFields, { snakecaseFields } from '../../formatFields'
import { addedNewNotification } from '../../../redux/notifications/actions'
import { getHeaders } from '../../axios/getHeaders'
import { dependenceType, instanceType, propInit } from '../type'

import * as typesRedux from '../actionTypes/actionTypes'
import HOST from '../../axios/host'

const WEBSOCKET_HOST = HOST.getInstance().getWebsocketRequestUrl()

class InstanceConnection {
  private _instance: instanceType
  constructor({
    uniqueName, path, dependencies, pathEvent, checkStatusCodeMessage, pathData
  }: propInit) {
    this._instance = {
      uniqueName,
      objectConnection: null,
      settings: {
        path: WEBSOCKET_HOST.concat(path),
        pathEvent, //путь до type при получении сообщения
        pathData,//путь до нужного респонса в сообщении
        checkStatusCodeMessage,// Проверять ли статус код сообщения, при отправке в стор
        dependencies: [
          {
            state: {
              actionTypeDependence: 'SET_TOKEN',
              pathActionType: 'payload.access',
              pathStore: 'user.token'
            },
            uniqueKey: '$TOKEN$'
          },
          {
            state: {
              actionTypeDependence: 'POST_AUTH_SUCCESS',
              pathActionType: 'payload.access',
              pathStore: 'user.token'
            },
            uniqueKey: '$TOKEN$'
          },
          ...dependencies
        ],
        urlConnection: null,
      },
      _dependenseTypeForClose: dependencies.reduce((accum: string[], dependece) => (
        dependece.action == 'close'
          ? [...accum, dependece.state.actionTypeDependence]
          : accum
      ), []),
      _isConnection: false, // подключение выполнено
      _isBlock: true, // блокировка пробы подключения, когда не было вызова createConnection
      _isErrorConnection: false, // была ошибка подключения
      _reconnect: false, // флан переподлкючения
      _awaitUpdateStore: false, // флаг ожидания нового стора, для попытки создания строки подключения
      _idReconnect: []
    }
  }



  createConnection(store: Store) {
    try {
      this._instance._isBlock = false
      this._instance.settings.urlConnection = this._createUrl(store.getState())
      if (this._instance.settings.urlConnection.search(/\$*\$/) !== -1) {
        this._instance._awaitUpdateStore = true
        return
      }
      this._flowConnection(store.dispatch, 'инициализация')
    } catch (error) {
      // @ts-ignore
      // store.dispatch(addedNewNotification({ message: "ошибка инициализации вебсокета" }))
      store.dispatch(addedNewNotification({ message: "" }))
      console.log('ERROR WEBSOKET MIDDLEWARE', error)
    }
  }

  updateConnection(store: Store, action: Action) {
    if (!this._instance._isBlock) {
      // console.group('Обновление соединения', this._instance.uniqueName);

      if (this._instance._dependenseTypeForClose?.includes(action.type) && this._instance._isConnection) {
        // console.log('!!! пришла зависимость на закрытие', action.type);
        this._instance._isBlock = true
        this.closeConnection()
        console.groupEnd()
        return
      }
      try {
        const updatedUrlConnection = this._createUrl(store.getState(), action)
        if (this._instance._awaitUpdateStore) {
          this._instance.settings.urlConnection = updatedUrlConnection
          if (this._instance.settings.urlConnection.search(/\$*\$/) == -1) {
            this.closeConnection()
            this._flowConnection(store.dispatch, 'апдейт была не полная строка подлкючения, теперь полная')
          }
          console.groupEnd()
          return
        }
        if (!this._instance._isConnection || this._instance._isErrorConnection) {
          this.closeConnection()
          this._instance.settings.urlConnection = updatedUrlConnection
          this._flowConnection(store.dispatch, `апдейт не был выполнен коннект `)
          console.groupEnd()
          return
        }
        if (this._instance.settings.urlConnection !== updatedUrlConnection) {
          this.closeConnection()
          this._instance.settings.urlConnection = updatedUrlConnection
          this._flowConnection(store.dispatch, 'апдейт новая строка подключения из-за нового стора')
          console.groupEnd()
        }
      } catch (error) {
        // @ts-ignore
        // store.dispatch(addedNewNotification({ message: "ошибка инициализации вебсокета" }))
        store.dispatch(addedNewNotification({ message: "" }))
        // console.log('ERROR WEBSOKET MIDDLEWARE', error)
        console.groupEnd()
      }
    }
  }

  closeConnection(isStrongClose?: boolean) {
    this._instance.objectConnection?.close(3001, 'upperClose')
    this._instance._reconnect = false
    this._instance._idReconnect.forEach((id) => {
      window.clearTimeout(id)
    })
    this._instance._idReconnect = []
    if (isStrongClose) {
      this._instance._isBlock = true
    }
  }

  getDependeciesActionType() {
    return this._instance.settings.dependencies.map(dependence => dependence.state.actionTypeDependence)
  }

  sendMessage(message: any) {
    this._instance.objectConnection?.readyState == 1 &&
      this._instance.objectConnection?.send(JSON.stringify(snakecaseFields(message)))
  }

  get nameConnection() {
    return this._instance.uniqueName
  }

  get stateConnection() {
    return this._instance.objectConnection?.readyState
  }

  private _createUrl(store: Store, action?: Action) {
    let resultUrl = this._instance.settings.path
    this._instance.settings.dependencies.forEach((dependece) => {
      let valueForReplace
      if (action?.type == dependece.state.actionTypeDependence) {
        valueForReplace = this._getValue(action, dependece.state.pathActionType, null)
        valueForReplace = (valueForReplace !== null)
          ? valueForReplace
          : this._getValue(store, dependece.state.pathStore, null)
      } else valueForReplace = this._getValue(store, dependece.state.pathStore, null)
      if (!valueForReplace && dependece.uniqueKey == '$TOKEN$') valueForReplace = getToken()
      if (valueForReplace !== null && valueForReplace !== undefined)
        resultUrl = resultUrl.replace(dependece.uniqueKey, valueForReplace)
    })
    return resultUrl
  }

  private _flowConnection(dispatch: any, reason?: string) {
    if (!this._instance.settings.urlConnection)
      throw new Error(`Ошибка неполная строка подключения${this._instance.uniqueName}`)
    else if (this._instance.settings.urlConnection.search(/\$*\$/) !== -1)
      throw new Error(`Ошибка неполная строка подключения${this._instance.uniqueName}`)

    this._instance._awaitUpdateStore = false
    this._instance._reconnect = false
    if (this._instance.settings.urlConnection) {
      console.log('url ', this._instance.settings.urlConnection)
      this._instance.objectConnection = new WebSocket(this._instance.settings.urlConnection)
      let uniqueTypeConnection = `_${this._instance.uniqueName.toUpperCase()}`

      // console.log('!!! пытаюсь законектится по причине', reason, this._instance.uniqueName, this._instance.objectConnection.readyState);

      this._instance.objectConnection.onopen = () => {
       //console.log('!!! connect success', this._instance.uniqueName)

        this._instance._isConnection = true
        this._instance._isErrorConnection = false
        this._instance._reconnect = false
        dispatch({
          type: typesRedux.WS_CONNECT_SUCCESS.concat(uniqueTypeConnection)
        })
      }

      this._instance.objectConnection.onclose = (event) => {
        console.log('!!! disconnect', this._instance.uniqueName, event)

        this._instance._isConnection = false
        this._instance._reconnect = true

        // Если закрытие из вне
        if (event.reason == 'upperClose') {
          this._instance._reconnect = false
          this._instance._idReconnect.forEach((id) => {
            window.clearTimeout(id)
          })
        }

        // если нужно делать переподключение
        if (this._instance.objectConnection?.readyState == 3 && this._instance._reconnect) {
          const timeOutId = window.setTimeout(async () => {
            await getHeaders()
            this._instance.objectConnection?.dispatchEvent(new Event('reconnect'))
          }, 3000)
          this._instance._idReconnect.push(timeOutId)
        }

        // если закрытие снаружи или закрытие без ошибки 
        if (!this._instance._isErrorConnection || event.reason == 'upperClose') {
          dispatch({
            type: typesRedux.WS_DISCONNECT_SUCCESS.concat(uniqueTypeConnection),
            payload: event
          })
        }
      }

      this._instance.objectConnection.onerror = event => {
        console.log('!!! error', event)
        this._instance._isErrorConnection = true
        dispatch({
          type: typesRedux.WS_HAS_BEEN_ERROR.concat(uniqueTypeConnection),
          paylaod: event
        })
      }

      this._instance.objectConnection.onmessage = (event) => {
        const response = camelcaseFields(JSON.parse(event.data))
        if (!this._instance.settings.checkStatusCodeMessage || response.status === 1000) {
          const eventMessage = this._instance.settings.pathEvent
            ? this._getValue(response, this._instance.settings.pathEvent, null)
            : response.event
          const responseData = this._instance.settings.pathData
            ? this._getValue(response, this._instance.settings.pathData, response)
            : response

          dispatch({
            type: `${eventMessage !== 'ERROR'
              ? typesRedux.WS_INCOMING_MESSAGE
              : typesRedux.WS_HAS_BEEN_ERROR
              }${uniqueTypeConnection}_${eventMessage}`.toUpperCase(),
            payload: responseData
          })
        }
      }

      this._instance.objectConnection.addEventListener('reconnect', () => {
        !this._instance._isBlock && this._flowConnection(dispatch, 'reconnect')
      })
    }
    // todo else

  }

  private _getValue(obj: any, path: string, defaultValue: any) {
    const keys = path.split('.')
    return keys.reduce((result, key) => result !== undefined ? result[key] : defaultValue, obj)
  }

}
export default InstanceConnection



type InstanceConnectionType = {
  _instanse: instanceType,
}
