import {
  SET_LOADING_GET_ALL_COMPANIES,
  GET_ALL_COMPANIES_SUCCESS,
  GET_ALL_COMPANIES_FAILED,
  SET_LOADING_POST_NEW_COMPANY,
  POST_NEW_COMPANY_SUCCESS,
  POST_NEW_COMPANY_FAILED,
  SET_LOADING_GET_ACTIVE_COMPANY_ACTIVE_USER,
  GET_ACTIVE_COMPANY_ACTIVE_USER_SUCCESS,
  GET_ACTIVE_COMPANY_ACTIVE_USER_FAILED,
  SET_LOADING_GET_ACTIVE_COMPANY_WORKER,
  GET_ACTIVE_COMPANY_WORKER_SUCCESS,
  GET_ACTIVE_COMPANY_WORKER_FAILED,
  POST_COMPANY_BY_INN_SUCCESS,
  POST_TOGGLE_LOADING_BY_INN,
  CLEAR_COMPANY_BY_INN,
  GET_LINK_FOR_TEMPLATE_SUCCESS,
  GET_TOGGLE_LOADING_LINK_TEMPLATE,
  POST_COMPANY_BY_INN_FAILED,
  DELETE_COMPANY_IN_ALLCOMPANIES,
  POST_COMPANY_BY_INN_RESET,
  CLEAR_ALL_COMPANIES_INFO,
  SET_HAS_BEEN_FETCHED,
  SET_LOADING_UPLOAD_COMPANY_FROM_EXCEL
} from './constants'

export const setLoadingGetAllCompanies = () => ({
  type: SET_LOADING_GET_ALL_COMPANIES
})
export const getAllCompaniesSuccess = (data) => ({
  type: GET_ALL_COMPANIES_SUCCESS,
  payload: data
})
export const clearAllCompaniesInfo = () => ({
  type: CLEAR_ALL_COMPANIES_INFO
})
export const getAllCompaniesFailed = () => ({
  type: GET_ALL_COMPANIES_FAILED
})

export const setLoadingPostNewCompany = () => ({
  type: SET_LOADING_POST_NEW_COMPANY
})
export const postNewCompanySuccess = (data) => ({
  type: POST_NEW_COMPANY_SUCCESS,
  payload: data
})
export const postNewCompanyFailed = () => ({
  type: POST_NEW_COMPANY_FAILED
})

export const setLoadingGetActiveCompanyActiveUser = () => ({
  type: SET_LOADING_GET_ACTIVE_COMPANY_ACTIVE_USER
})
export const getActiveCompanyActiveUserSuccess = (data) => ({
  type: GET_ACTIVE_COMPANY_ACTIVE_USER_SUCCESS,
  payload: data
})
export const getActiveCompanyActiveUserFailed = () => ({
  type: GET_ACTIVE_COMPANY_ACTIVE_USER_FAILED
})

export const setLoadingGetActiveCompanyWorker = () => ({
  type: SET_LOADING_GET_ACTIVE_COMPANY_WORKER
})
export const getActiveCompanyWorkerSuccess = (data) => ({
  type: GET_ACTIVE_COMPANY_WORKER_SUCCESS,
  payload: data
})
export const getActiveCompanyWorkerFailed = () => ({
  type: GET_ACTIVE_COMPANY_WORKER_FAILED
})

export const postCompanyByInnSuccess = (data) => ({
  type: POST_COMPANY_BY_INN_SUCCESS,
  payload: data
})
export const postCompanyByInnFailed = () => ({
  type: POST_COMPANY_BY_INN_FAILED
})
export const postCompanyByInnReset = () => ({
  type: POST_COMPANY_BY_INN_RESET
})
export const postToggleLoadingByInn = () => ({
  type: POST_TOGGLE_LOADING_BY_INN
})
export const clearCompanyByInn = () => ({
  type: CLEAR_COMPANY_BY_INN
})

export const getLinkTempalteExcel = (payload) => ({
  type: GET_LINK_FOR_TEMPLATE_SUCCESS,
  payload
})
export const getToggleLoadingLinkTempalte = () => ({
  type: GET_TOGGLE_LOADING_LINK_TEMPLATE
})
export const deleteCopmanyInAllCompanies = (id) => ({
  type: DELETE_COMPANY_IN_ALLCOMPANIES,
  payload: id
})

export const setHasBeenFetched = (payload) => ({
  type: SET_HAS_BEEN_FETCHED,
  payload
})

export const setLoadingUploadCompanyFromExcel = (payload) => ({
  type: SET_LOADING_UPLOAD_COMPANY_FROM_EXCEL,
  payload
})