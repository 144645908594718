import React from 'react'
import { IIcon } from '../../../models/IIcon'

const Minus: React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color='#5C5C5C',
  ...innerProps
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z" fill={color} />
  </svg>
)
export default Minus