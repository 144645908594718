import React from 'react'

const DocumentsIcon = ({ width = '2.4', height = '2.4', color = 'black', ...innerProps }) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 26 20" fill={color} xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_125:8957)">
  <path d="M19.1579 2.98333L22.2698 7.00833C22.5785 7.325 22.7492 7.75 22.7492 8.19167V15.8333C22.7492 16.75 22.0179 17.5 21.1241 17.5H16.6553C17.8741 17.5 18.2459 16.6667 18.2459 15.8333V14.1667H20.3116V12.5H18.2459V10.8333H20.3116V9.16667H18.2459V8.19167C18.2459 7.85315 18.0463 7.46773 17.8741 7.21024V5.83333H16.8178L14.2178 2.5H18.0122C18.4428 2.5 18.8572 2.675 19.1579 2.98333Z" fill={color}/>
  </g>
  <g clipPath="url(#clip1_125:8957)">
  <path d="M15.7708 7.00833L12.6589 2.98333C12.3583 2.675 11.9439 2.5 11.5133 2.5H4.87503C3.98126 2.5 3.25 3.25 3.25 4.16667V15.8333C3.25 16.75 3.98126 17.5 4.87503 17.5H14.6252C15.519 17.5 16.2502 16.75 16.2502 15.8333V8.19167C16.2502 7.75 16.0796 7.325 15.7708 7.00833ZM5.68754 5.83333H11.3751V7.5H5.68754V5.83333ZM13.8127 14.1667H5.68754V12.5H13.8127V14.1667ZM13.8127 10.8333H5.68754V9.16667H13.8127V10.8333Z" fill={color}/>
  </g>
  <defs>
  <clipPath id="clip0_125:8957">
  <rect width="19.5003" height="20" fill="white" transform="translate(6.49902)"/>
  </clipPath>
  <clipPath id="clip1_125:8957">
  <rect width="19.5003" height="20" fill="white"/>
  </clipPath>
  </defs>
  </svg>
)

export default DocumentsIcon