import React from 'react'
import { IIcon } from '../../../models/IIcon'

const DocumentsMenu: React.FC<IIcon> = ({
  width = '2.4', height = '2.4', color = '#5C5C5C', ...innerProps
}) => (
  <svg width={`${width}rem`} height={`${height}rem`} {...innerProps} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 2C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2H6ZM13 9V3.5L18.5 9H13Z" fill={color} />
  </svg>
)
export default DocumentsMenu