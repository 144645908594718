import React from 'react'
import { IIcon } from '../../../models/IIcon'

const BigFilter: React.FC<IIcon> = ({
  width = '18.7',
  height = '17.7',
  color = '#1A65DA',
  ...innerProps
}) => (
  <svg width={`${width}rem`} height={`${height}rem`} {...innerProps} viewBox="0 0 187 177" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.165039" y="11.6372" width="164.998" height="164.998" rx="82.4989" fill="#E8F1FB"/>
    <path d="M84.3805 61.1199H122.289L103.297 85.0025L84.3805 61.1199ZM73.9556 59.6414C81.6132 69.4598 95.7532 87.6561 95.7532 87.6561V110.401C95.7532 112.486 97.4591 114.192 99.5441 114.192H107.126C109.211 114.192 110.917 112.486 110.917 110.401V87.6561C110.917 87.6561 125.019 69.4598 132.676 59.6414C134.61 57.1394 132.828 53.5381 129.682 53.5381H76.9504C73.804 53.5381 72.0222 57.1394 73.9556 59.6414Z" fill="#1A65DA"/>
    <rect x="20.835" y="1.36523" width="165" height="165" rx="82.5" stroke="#1A65DA" strokeWidth="2" strokeLinecap="round" strokeDasharray="10 15"/>
  </svg>
)
export default BigFilter;