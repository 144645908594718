import React from 'react'

const EmptyDocuments = ({ width = '14.1', height = '13.3', innerProps }) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 141 133" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.58252" y="9.15173" width="123.377" height="123.377" rx="61.6887" fill="#E8F1FB" />
    <g clipPath="url(#clip0_227:21797)">
      <path d="M56.0793 40.4839V40.4832C56.0793 37.9109 58.1609 35.814 60.7202 35.814H81.0446C82.2837 35.814 83.469 36.3049 84.3249 37.184L84.3249 37.1841L84.3343 37.1935L98.0256 50.8848L98.0256 50.8849L98.0351 50.8942C98.9142 51.7502 99.4052 52.9354 99.4052 54.1746V85.8375C99.4052 88.4033 97.3017 90.5068 94.7359 90.5068H60.6918C58.1327 90.5068 56.0512 88.4102 56.0509 85.8381C56.0509 85.8379 56.0509 85.8377 56.0509 85.8375L56.0793 40.4839ZM79.5627 43.8944V51.8218C79.5627 53.9332 81.286 55.6565 83.3973 55.6565H91.3248C93.9975 55.6565 95.336 52.425 93.4461 50.5351L84.684 41.773C82.7941 39.8831 79.5627 41.2216 79.5627 43.8944Z" stroke="#1A65DA" stroke-width="2" />
    </g>
    <rect x="16.0385" y="1.47083" width="123.379" height="123.379" rx="61.6895" stroke="#1A65DA" stroke-width="2" stroke-linecap="round" stroke-dasharray="10 15" />
    <defs>
      <clipPath id="clip0_227:21797">
        <rect width="68.0314" height="68.0314" fill="white" transform="translate(43.7123 29.1447)" />
      </clipPath>
    </defs>
  </svg>
)

export default EmptyDocuments