import { isBoolean, isNumber, isString } from 'lodash'

export interface LocationLeafModel {
  hasChildren: boolean
  id: number
  name: string
  previous: number
  project: number
}

export class LocationLeafAdapter {
  private model: LocationLeafModel = {
    hasChildren: false,
    id: NaN,
    name: '',
    previous: NaN,
    project: NaN
  }

  constructor(data: any) {
    try {
      if (isBoolean(data.has_children))
        this.model.hasChildren = data.has_children
      if (isNumber(data.id))
        this.model.id = data.id
      if (isString(data.name))
        this.model.name = data.name
      if (isNumber(this.model.previous))
        this.model.previous = data.previous
      if (isNumber(this.model.project))
        this.model.project = data.project
    } catch(e) {
      console.error(e)
    }
  } 

  getFields() {
    return this.model
  }
}