import * as TYPE from './actionTypes.js'

export const putProfileInfoSucceed = payload => ({ type: TYPE.PUT_PROFILE_INFO_SUCCEED, payload })

export const postProfileDocumentFailed = payload =>
  ({ type: TYPE.POST_PROFILE_DOCUMENT_FAILED, payload })

export const postProfileDocumentSucceed = payload =>
  ({ type: TYPE.POST_PROFILE_DOCUMENT_SUCCEED, payload })

export const setUserAccess = profile => ({
  type: TYPE.LOG_IN,
  payload: profile,
})

//подключение АПИ

export const setConfirmInviteLoading = () => ({
  type: TYPE.SET_CONFIRM_INVITE_LOADING
})
export const getConfirmInviteSuccess = (email) => ({
  type: TYPE.GET_CONFIRM_INVITE_SUCCESS,
  payload: email
})
export const getConfirmInviteFailed = () => ({
  type: TYPE.GET_CONFIRM_INVITE_FAILED
})

export const setLoadingRegisterUserStart = () => ({
  type: TYPE.SET_LOADING_REGISTER_USER_START
})
export const postRegisterUserSuccess = (userInfo) => ({
  type: TYPE.POST_REGISTER_USER_SUCCESS,
  payload: userInfo
})
export const postRegisterUserFailed = (error) => ({
  type: TYPE.POST_REGISTER_USER_FAILED,
  payload: error
})

export const setLoadingAuthStart = () => ({
  type: TYPE.SET_LOADING_AUTH_START
})

export const postAuthSuccess = (data) => ({
  type: TYPE.POST_AUTH_SUCCESS,
  payload: data
})

export const postAuthFailed = (errorMessage) => ({
  type: TYPE.POST_AUTH_FAILED,
  payload: errorMessage
})

export const setAccessToken = (token) => ({
  type: TYPE.SET_TOKEN,
  payload: {
    access: token
  }
})

export const setLoadingAuthRefresh = () => ({
  type: TYPE.SET_LOADING_AUTH_REFRESH_START
})

export const postAuthRefreshSuccess = (token) => ({
  type: TYPE.POST_AUTH_REFRESH_SUCCESS,
  payload: token
})

export const postAuthRefreshFailed = () => ({
  type: TYPE.POST_AUTH_REFRESH_FAILED
})

export const setLoadingGetUser = () => ({
  type: TYPE.SET_LOADING_GET_USER
})

export const getUserSuccess = (data) => ({
  type: TYPE.GET_USER_SUCCESS,
  payload: data
})

export const getUserFailed = () => ({
  type: TYPE.GET_USER_FAILED
})

export const setLoadingPutUser = () => ({
  type: TYPE.SET_LOADING_PUT_USER_START
})

export const putUserSuccess = (userInfo) => ({
  type: TYPE.PUT_USER_SUCCESS,
  payload: userInfo
})

export const putUserFailed = () => ({
  type: TYPE.PUT_USER_FAILED
})

export const setLoadingPostSignature = () => ({
  type: TYPE.SET_LOADING_POST_SIGNATURE
})

export const postSignatureSuccess = (signature) => ({
  type: TYPE.POST_SIGNATURE_SUCCESS,
  payload: signature
})

export const postSignatureFailed = () => ({
  type: TYPE.POST_SIGNATURE_FAILED
})

export const toggleLoadingPostNewPassword = () => ({
  type: TYPE.TOGGLE_LOADING_POST_NEW_PASSWORD
})
export const postNewPasswordFailed = (error) => ({
  type: TYPE.POST_NEW_PASSWORD_FAILED,
  payload: error
})
export const postNewPasswrodSucces = () => ({
  type: TYPE.POST_NEW_PASSWORD_SUCCESS
})
export const clearErrorPostNewPassword = () => ({
  type: TYPE.CLEAR_ERROR_POST_NEW_PASSWROD
})

export const setLoadingGetConfirmPasswordStart = () => ({
  type: TYPE.SET_LOADING_GET_CONFIRM_PASSWORD_START
})
export const setLoadingGetConfirmPasswordFinish = () => ({
  type: TYPE.SET_LOADING_GET_CONFIRM_PASSWORD_FINISH
})

export const getUserOnboardingSuccess = (data) => ({
  type: TYPE.GET_USER_ONBOARDIN_SUCCESS,
  payload: data
})
export const togglePutOnbordingLoading = () => ({
  type: TYPE.TOGGLE_PUT_ONBOARDING_LOADING
})

export const putUserOnboardingSuccess = (data) => ({
  type: TYPE.PUT_USER_ONBOARDING_SUCCESS,
  payload: data
})

export const postResetPasswordFailed = (data) => ({
  type: TYPE.POST_RESET_PASSWORD_FAILED,
  payload: data
})
export const putResetPasswordFailed = (data) => ({
  type: TYPE.PUT_RESET_PASSWORD_FAILED,
  payload: data
})
export const setLoadingResetPassword = () => ({
  type: TYPE.SET_LOADING_RESET_PASSWORD
})
export const postResetPasswordSuccess = () => ({
  type: TYPE.POST_RESET_PASSWORD_SUCCESS
})

//---TENANTS---
export const setTenantsList = (payload) => ({
  type: TYPE.SET_TENANTS_LIST,
  payload
})
export const clearTenants = () => ({
  type: TYPE.CLEAR_TENANTS_LIST
})
export const setIsLoadingTenants = (payload) => ({
  type: TYPE.SET_IS_LOADING_TENANTS,
  payload
})


export const getUserRoleInProjectSuccess = (payload) => ({
  type: TYPE.GET_USER_ROLE_IS_PROJECT_SUCCESS,
  payload
})
export const setUserRoleLoading = (payload) => ({
  type: TYPE.SET_USER_ROLE_LODAING,
  payload
})

export const putUserCompanyInfo = (payload) => ({
  type: TYPE.PUT_USER_COMPANY_INFO,
  payload
})