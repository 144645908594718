import React from 'react'
import { IIcon } from './IIcon'

export const FaviconSL: React.FC<IIcon> = () => (
  <svg width="72" height="50" viewBox="0 0 72 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M48.7465 1.62769V41.4328H71.4786L70.5153 48.2958H40.9766V1.62769H48.7465Z" fill="#1A65DA"/>
    <path
      d="M7.9343 34.047C8.27678 37.0972 9.34702 39.3195 11.145 40.7138C12.9859 42.1082 15.7043 42.8054 19.3003 42.8054C21.0555 42.8054 22.5325 42.6093 23.7311 42.2171C24.9298 41.825 25.893 41.3021 26.6208 40.6485C27.3486 39.9949 27.8623 39.2323 28.1619 38.3608C28.4616 37.4458 28.6115 36.4871 28.6115 35.4849C28.6115 34.7442 28.5044 34.0252 28.2904 33.328C28.0763 32.5872 27.691 31.9118 27.1345 31.3018C26.578 30.6917 25.8288 30.1689 24.887 29.7331C23.9452 29.2538 22.7465 28.8834 21.291 28.622L15.4474 27.5108C13.1357 27.0751 11.0808 26.5304 9.28281 25.8768C7.4848 25.2232 5.96505 24.3735 4.72356 23.3277C3.52489 22.2383 2.60447 20.9311 1.96233 19.406C1.36299 17.8373 1.06332 15.9418 1.06332 13.7195C1.06332 11.4973 1.49142 9.5582 2.34761 7.90238C3.24662 6.24656 4.4667 4.87397 6.00786 3.78461C7.54901 2.65168 9.36843 1.82376 11.4661 1.30087C13.6066 0.734405 15.9183 0.451172 18.4013 0.451172C21.5692 0.451172 24.2234 0.821553 26.3639 1.56232C28.5044 2.30308 30.2382 3.2835 31.5653 4.50358C32.8924 5.68009 33.8985 7.05268 34.5834 8.62135C35.2684 10.19 35.7393 11.8241 35.9961 13.5235L28.6757 14.7C28.0763 12.0855 27.0275 10.1465 25.5291 8.8828C24.0736 7.61915 21.7405 6.98732 18.5297 6.98732C16.6889 6.98732 15.1478 7.16162 13.9063 7.51021C12.7076 7.81523 11.723 8.27276 10.9524 8.8828C10.2246 9.44927 9.6895 10.1247 9.34702 10.909C9.04735 11.6498 8.89752 12.4341 8.89752 13.262C8.89752 15.31 9.43264 16.8351 10.5029 17.8373C11.6159 18.8395 13.5424 19.6239 16.2822 20.1903L22.6395 21.4322C27.477 22.3473 31.0302 23.9159 33.2991 26.1382C35.5681 28.3169 36.7025 31.4761 36.7025 35.6156C36.7025 37.5765 36.3386 39.4066 35.6109 41.106C34.9259 42.7618 33.8557 44.2216 32.4001 45.4852C30.9446 46.7053 29.1252 47.6639 26.9419 48.3611C24.8014 49.1019 22.297 49.4723 19.4287 49.4723C13.5638 49.4723 8.96173 48.2304 5.62257 45.7467C2.2834 43.2194 0.442576 39.3195 0.100098 34.047H7.9343Z"
      fill="#333333"/>
  </svg>
)