import React from 'react'
import { DropDownProps, Dropdown } from "antd";

import cn from 'classnames'

import './ActionOnUserPopup.scss'

export const ActionOnUserPopup = <T extends IActionOnUserOption>({
  options,
  onClickOption,
  children,
  open,
  ...props
}: React.PropsWithChildren<PropType<T>>) => (
  <Dropdown
    overlayClassName='user-in-table-action-card_popup'
    overlayStyle={{ 'scale': '0.8' }}
    open={open}
    getPopupContainer={() => document.getElementById('popup-container')!}
    dropdownRender={() => (
      <div className='options'>
        {options.map((option) => (
          <div
            className={cn('options_item', option.className)}
            onClick={() => onClickOption(option)}
          >
            {option?.icon}
            <p>{option.name}</p>
          </div>
        ))}
      </div>
    )}
    {...props}
  >
    {children}
  </Dropdown>
)

type PropType<T extends IActionOnUserOption> = DropDownProps & {
  options: T[];
  onClickOption: (option: T) => void;
};

export interface IActionOnUserOption {
  id: number | string;
  name: string;
  icon?: React.ReactElement;
  className?: string;
}
