import React from 'react'
import { formatDateFromString } from '../../../../utils/dateUtils'
import { unparseTags } from '../../../../utils/pasrseTags'
import { INotification, NotificationSystemType } from '../../../../models/INotification'

import ReactHtmlParser from 'html-react-parser'
import cn from 'classnames'
import * as Icon from '../../icons'

import './AppNotificationCard.scss'

export type AppNotificationCardType = {
  notificationData: INotification
  handleNotificationClick: (notification: INotification) => void
}

const renderIconType = (type: NotificationSystemType) => {
  switch (type) {
    case 'document_discussion':
    case 'mafs':
    case 'docflow':
      return <Icon.DocumentsMenu color='#1A65DA' />
    case 'chats':
      return <Icon.Chat color='#1A65DA' />
    case 'calendars':
      return <Icon.CalendarMenuItem color='#1A65DA' />
    case 'projects':
      return <Icon.Project />
  }
}

const AppNotificationCard: React.FC<AppNotificationCardType> = ({
  notificationData,
  handleNotificationClick
}) => (
  <div
    key={notificationData.id}
    className={cn('app-notification-card', notificationData.notificationType, {
      'app-notification-card_is-readed': notificationData.isRead
    })}
    onClick={() => handleNotificationClick(notificationData)}
  >
    <div className='app-notification-card__header'>
      {renderIconType(notificationData.notificationType)}
      {notificationData.header}
    </div>
    <div className='app-notification-card__body'>
      <div className='main-info'>
        <span className='main-info__title'>
          {notificationData.title}
        </span>
        <span className='main-info__body'>
          {notificationData.body && typeof notificationData.body == 'string' && ReactHtmlParser(unparseTags(notificationData.body))}
        </span>
      </div>
      <div className='app-notification-card__time'>
        {formatDateFromString.toTimeAndDate(notificationData.sentTime).time}
      </div>
    </div>
  </div>
)


export default AppNotificationCard