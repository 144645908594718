import React from 'react';

const TemplateDocument = ({ width = '1.6', height = '2.0', color = '#5c5c5c', opacity = '1', className, ...innerProps }) => (
  <div className={className}>
    <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 0C0.9 0 0.0100002 0.9 0.0100002 2L0 18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6L10 0H2ZM9 7V1.5L14.5 7H9Z" fill={color} fillOpacity={opacity}/>
    </svg>
  </div>
);

export default TemplateDocument;