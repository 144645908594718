import React from 'react'
import { IIcon } from "../../../models/IIcon"

const Asterix: React.FC<IIcon> = ({
  width = '1.2',
  height = '1.2',
  color = '#000000',
  ...innerProps
}) => {
  return (
    <svg width={`${width}rem`} height={`${height}rem`} {...innerProps} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.00024 0H7.00024V4.07899L10.3416 1.73938L11.4887 3.37768L7.74366 5.99999L11.4887 8.6223L10.3415 10.2606L7.00024 7.921V12H5.00024V7.92095L1.65888 10.2606L0.511728 8.6223L4.25676 6L0.511719 3.37769L1.65887 1.73938L5.00024 4.07904V0Z" fill="black"/>
    </svg>
  )
}

export default Asterix