import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EntityOption } from '../../components/UI/InputEntityTags'
import { LocationsTree } from '../../../fsd/entities/Location'

export type PassesFiltersType = {
  searchText: string
  dateOrder: 'asc' | 'desc' | null
  activeTab: 'users' | 'vehicles'
  dateStart: string
  dateEnd: string
  statuses: EntityOption[]
  authors: EntityOption[]
  locations: LocationsTree<number>
}

export const initialState: PassesFiltersType = {
  searchText: '',
  dateOrder: null,
  activeTab: 'vehicles',
  dateStart: '',
  dateEnd: '',
  statuses: [],
  authors: [],
  locations: {
    location: [],
    exactLocation: []
  }
}

const passesFiltersSlice = createSlice({
  name: 'passesFiltersSlice',
  initialState: initialState,
  reducers: {
    setSearchTextFilter: (state, { payload }: PayloadAction<string>) => {
      state.searchText = payload
    },
    toggleDateOrderFilter: (state) => {
      if (state.dateOrder === 'desc' || state.dateOrder === null)
        state.dateOrder = 'asc'
      else
        state.dateOrder = 'desc'
    },
    setActiveTab: (state, { payload }: PayloadAction<PassesFiltersType['activeTab']>) => {
      state.activeTab = payload
    },
    setSearchText: (state, { payload }: PayloadAction<string>) => {
      state.searchText = payload
    },
    setStatuses: (state, { payload }: PayloadAction<EntityOption[]>) => {
      state.statuses = payload
    },
    setAuthors: (state, { payload }: PayloadAction<EntityOption[]>) => {
      state.authors = payload
    },
    setDateStart: (state, { payload }: PayloadAction<string>) => {
      state.dateStart = payload
    },
    setDateEnd: (state, { payload }: PayloadAction<string>) => {
      state.dateEnd = payload
    },
    setLocations: (state, { payload }: PayloadAction<PassesFiltersType['locations']>) => {
      state.locations = payload
    },
    resetFilters: () => initialState
  }
})

export const passesFiltersActions = passesFiltersSlice.actions

export default passesFiltersSlice.reducer