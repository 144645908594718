import { IUserCheckpointModel, UserCheckpointAdapter } from './UserCheckpointAdapter'
import { omitBy, isUndefined } from 'lodash'

export class PutCheckpointByIdQuery {
  query = (userCheckpoint: IUserCheckpointModel & { projectId: number }) => {
    console.log()

    const postData = omitBy({
      id: userCheckpoint.id,
      access_date_start: userCheckpoint.accessDateStart,
      access_date_end: userCheckpoint.accessDateEnd,
      project: userCheckpoint.projectId,
      comment: userCheckpoint.comment || undefined,
      registered_visitor: userCheckpoint.registeredVisitor
        ? userCheckpoint.registeredVisitor
        : undefined,
      guest: userCheckpoint.registeredVisitor ? undefined : userCheckpoint.guest,
      contact_user: userCheckpoint.contactUser || undefined,
      contact_phone: userCheckpoint.contactPhone || undefined,
      location: {
        location: userCheckpoint.location.location.concat(userCheckpoint.location.exactLocation),
        // exact_location: carCheckpoint.location.exactLocation
        exact_location: []
      },
      attachments: userCheckpoint.attachments.map(a => a.attachmentId)
    }, isUndefined)

    return {
      url: `checkpoints/users/${postData.id}`,
      method: 'PUT',
      body: postData
    }
  }

  transformResponse = (response: any) => {
    return new UserCheckpointAdapter(response).getFields()
  }
}