import * as TYPE from './actionTypes'
import { setToken } from '../../utils/localStorageManagement'

const initialState = {
  isLoadingConfirmInvite: false,
  isLoadingRegisterUser: false,
  isLoadingAuth: false,
  isLoadingGetUser: false,
  isLoadingPutUser: false,
  isLoadingUserRole: 'idle',
  userInfo: {},
  userRoleInProject: null,
  inviteInfo: {},
  token: '',
  projects: [],
  isLoadingSignature: false,
  errorMessageAuth: '',
  errorMessageSign: {},
  errorMessageChangePass: null,
  passwordHasBeenChanged: false,
  isLoadingPostNewPassword: false,
  isLoadingConfirmPassword: false,
  onboarding: null,
  getOnboardingSucces: false,
  putOnboardingLoading: false,
  errorMessageResetPassword: '',
  isLoadingResetPassword: false,
  isSuccessResetPassword: false,
  tenantsList: null,
  isLoadingTenants: false
}

export const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {

    case TYPE.SET_CONFIRM_INVITE_LOADING:
      return { ...state, isLoadingConfirmInvite: true }

    case TYPE.GET_CONFIRM_INVITE_SUCCESS:
      return {
        ...state,
        isLoadingConfirmInvite: false,
        inviteInfo: {
          ...payload
        },
        userInfo: {
          ...state.userInfo,
          email: payload.email,
          isAdmin: payload.companyRole,
          companyRole: payload.companyRole,
          alreadyRegistered: payload.alreadyRegistered,
        }
      }

    case TYPE.GET_CONFIRM_INVITE_FAILED:
      return {
        ...state,
        isLoadingConfirmInvite: false,
      }

    case TYPE.SET_LOADING_REGISTER_USER_START:
      return {
        ...state,
        isLoadingRegisterUser: true
      }

    case TYPE.POST_REGISTER_USER_SUCCESS:
      setToken(payload)
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...payload.user
        },
        token: payload.token,
        isLoadingRegisterUser: false,
        errorMessageSign: {}
      }

    case TYPE.POST_REGISTER_USER_FAILED:
      return {
        ...state,
        isLoadingRegisterUser: false,
        errorMessageSign: payload
      }

    case TYPE.SET_LOADING_AUTH_START:
      return {
        ...state,
        isLoadingAuth: true,
        errorMessageAuth: ''
      }

    case TYPE.POST_AUTH_SUCCESS:
      setToken(payload)
      return {
        ...state,
        isLoadingAuth: false,
        userInfo: payload.user,
        token: payload.access,
        errorMessageAuth: ''
      }

    case TYPE.POST_AUTH_FAILED:
      return {
        ...state,
        isLoadingAuth: false,
        errorMessageAuth: payload
      }

    case TYPE.SET_TOKEN:
      return {
        ...state,
        token: payload.access
      }

    case TYPE.SET_LOADING_GET_USER:
      return {
        ...state,
        isLoadingGetUser: true
      }

    case TYPE.GET_USER_SUCCESS:
      return {
        ...state,
        isLoadingGetUser: false,
        userInfo: payload
      }

    case TYPE.GET_USER_FAILED:
      return {
        ...state,
        isLoadingGetUser: false
      }

    case TYPE.SET_LOADING_PUT_USER_START:
      return {
        ...state,
        isLoadingPutUser: true,
      }

    case TYPE.PUT_USER_SUCCESS:
      return {
        ...state,
        isLoadingPutUser: false,
        userInfo: payload,
      }

    case TYPE.PUT_USER_FAILED:
      return {
        ...state,
        isLoadingPutUser: false,
      }

    case TYPE.SET_LOADING_POST_SIGNATURE:
      return { ...state, isLoadingSignature: true }

    case TYPE.POST_SIGNATURE_SUCCESS:
      return {
        ...state,
        isLoadingSignature: false,
        userInfo: { ...state.userInfo, sign: payload }
      }

    case TYPE.POST_SIGNATURE_FAILED:
      return {
        ...state,
        isLoadingSignature: false
      }

    case TYPE.TOGGLE_LOADING_POST_NEW_PASSWORD:
      return {
        ...state,
        isLoadingPostNewPassword: !state.isLoadingPostNewPassword
      }
    case TYPE.POST_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordHasBeenChanged: true,
        errorMessageChangePass: null
      }
    case TYPE.POST_NEW_PASSWORD_FAILED:
      return {
        ...state,
        passwordHasBeenChanged: false,
        errorMessageChangePass: payload
      }
    case TYPE.CLEAR_ERROR_POST_NEW_PASSWROD:
      return {
        ...state,
        errorMessageChangePass: null
      }

    case TYPE.SET_LOADING_GET_CONFIRM_PASSWORD_START:
      return {
        ...state,
        isLoadingConfirmPassword: true
      }
    case TYPE.SET_LOADING_GET_CONFIRM_PASSWORD_FINISH:
      return {
        ...state,
        isLoadingConfirmPassword: false
      }

    case TYPE.GET_USER_ONBOARDIN_SUCCESS:
      return {
        ...state,
        onboarding: payload,
        getOnboardingSucces: true
      }

    case TYPE.PUT_USER_ONBOARDING_SUCCESS:
      return {
        ...state,
        onboarding: payload
      }
    case TYPE.TOGGLE_PUT_ONBOARDING_LOADING:
      return {
        ...state,
        putOnboardingLoading: !state.putOnboardingLoading
      }

    case TYPE.POST_RESET_PASSWORD_FAILED:
      return {
        ...state,
        errorMessageResetPassword: payload,
        isLoadingResetPassword: false
      }

    case TYPE.SET_LOADING_RESET_PASSWORD:
      return {
        ...state,
        isLoadingResetPassword: !state.isLoadingResetPassword
      }

    case TYPE.PUT_RESET_PASSWORD_FAILED:
      return {
        ...state,
        errorMessageResetPassword: payload,
        isLoadingResetPassword: false
      }

    case TYPE.POST_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isSuccessResetPassword: true,
        isLoadingResetPassword: false,
        errorMessageResetPassword: ''
      }

    //---TENANTS---
    case TYPE.SET_TENANTS_LIST:
      return {
        ...state,
        tenantsList: payload
      }
    case TYPE.CLEAR_TENANTS_LIST:
      return {
        ...state,
        tenantsList: null
      }
    case TYPE.SET_IS_LOADING_TENANTS:
      return {
        ...state,
        isLoadingTenants: payload
      }
    case TYPE.GET_USER_ROLE_IS_PROJECT_SUCCESS:
      return {
        ...state,
        userRoleInProject: payload
      }
    case TYPE.SET_USER_ROLE_LODAING:
      return {
        ...state,
        isLoadingUserRole: payload
      }

    case TYPE.PUT_USER_COMPANY_INFO:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          company: payload
        }
      }
    default: return state
  }
}