import { MetaAdapter } from './../MetaAdapter';
import { ICompany } from '../../models/ICompany'
import { CompanyAdapter } from './CompanyAdapter'
import QueryString from 'query-string'
import snakecaseKeys from 'snakecase-keys'

export class GetCompanyListQuery {
  query = ({
    page,
    perPage,
    projectId,
    searchText,
    excludeProjectCompanies = false,
    project
  }: Partial<{
    page: number
    perPage: number
    projectId: number
    searchText: string
    excludeProjectCompanies: boolean
    project: number
  }>): string => {
    const queryString = QueryString.stringify(snakecaseKeys({
      search: searchText,
      page,
      perPage,
      project,
      excludeProjectCompanies
    }), { skipNull: true })

    return `companies?${queryString}`
  }

  transformResponse = (response: { results: ICompany[], meta: Record<string, unknown> }) => {
    const { meta, results } = response

    const listOptions = results.map((user: ICompany) =>
      new CompanyAdapter(user).getFields()
    )

    return { meta: new MetaAdapter(meta).getFields(), listOptions }
  }

  transformErrorResponse = () => {
    return { meta: new MetaAdapter({}).getFields(), listOptions: [] }
  }
}