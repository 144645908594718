import React from 'react'
import { IIcon } from '../../../models/IIcon'

const DateTime: React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  ...innerProps
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 3H15C16.1 3 17 3.9 17 5V8H15V7H4V16H8V18H4C2.89 18 2 17.1 2 16L2.01 5C2.01 3.9 2.89 3 4 3H5V1H7V3H12V1H14V3Z" fill="#5C5C5C" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9 16C9 12.134 12.134 9 16 9C19.866 9 23 12.134 23 16C23 19.866 19.866 23 16 23C12.134 23 9 19.866 9 16ZM16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11ZM15 12H17V15H18V17H15V12Z" fill="#5C5C5C" />
  </svg>

)
export default DateTime