import React from 'react'
import { IIcon } from '../../../models/IIcon'

const EditDocument: React.FC<IIcon> = ({
  width = '2.2',
  height = '2.2',
  color = '#5C5C5C',
  ...innerProps
}) => (
  <svg width={`${width}rem`} height={`${height}rem`} {...innerProps} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.04102 20.1667C4.67435 20.1667 4.35352 20.0292 4.07852 19.7542C3.80352 19.4792 3.66602 19.1583 3.66602 18.7917V3.20834C3.66602 2.84168 3.80352 2.52084 4.07852 2.24584C4.35352 1.97084 4.67435 1.83334 5.04102 1.83334H12.8327L18.3327 7.33334V11.8708L11.916 18.2875V20.1667H5.04102ZM13.291 20.1667V18.8604L17.9431 14.2313L19.2723 15.4917L14.5973 20.1667H13.291ZM19.891 14.8729L18.5848 13.5896L19.3181 12.8792C19.4556 12.757 19.6198 12.6958 19.8108 12.6958C20.0018 12.6958 20.1584 12.757 20.2806 12.8792L20.5785 13.2C20.716 13.3375 20.7848 13.5017 20.7848 13.6927C20.7848 13.8837 20.716 14.0479 20.5785 14.1854L19.891 14.8729ZM11.916 8.25001H16.9577L11.916 3.20834V8.25001Z" fill={color} />
  </svg>
)

export default EditDocument