import { IIcon } from '../../../models/IIcon'

import React from 'react'

const ArrowBackFat: React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  ...innerProps
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M21.0001 12.0001C21.0001 12.199 20.9211 12.3898 20.7804 12.5304C20.6398 12.6711 20.449 12.7501 20.2501 12.7501H11.2501V18.7501C11.2502 18.8985 11.2063 19.0436 11.1239 19.1671C11.0415 19.2905 10.9243 19.3868 10.7872 19.4436C10.6501 19.5004 10.4992 19.5152 10.3536 19.4863C10.2081 19.4573 10.0744 19.3857 9.96948 19.2807L3.21948 12.5307C3.14974 12.4611 3.09443 12.3784 3.05668 12.2873C3.01894 12.1963 2.99951 12.0987 2.99951 12.0001C2.99951 11.9015 3.01894 11.8039 3.05668 11.7129C3.09443 11.6218 3.14974 11.5391 3.21948 11.4695L9.96948 4.71948C10.0744 4.61447 10.2081 4.54294 10.3536 4.51396C10.4992 4.48497 10.6501 4.49982 10.7872 4.55664C10.9243 4.61345 11.0415 4.70967 11.1239 4.83312C11.2063 4.95656 11.2502 5.10168 11.2501 5.2501V11.2501H20.2501C20.449 11.2501 20.6398 11.3291 20.7804 11.4698C20.9211 11.6104 21.0001 11.8012 21.0001 12.0001Z' fill={color} />
  </svg>
)
export default ArrowBackFat


