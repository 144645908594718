import { IUserCheckpointModel, UserCheckpointAdapter } from './UserCheckpointAdapter'
import QueryString from 'query-string'
import snakecaseKeys from 'snakecase-keys'

export type QueryParams = {
  limit: number
  offset: number
  orderBy: string
  project: number
  search: string
  dateStart: string
  dateEnd: string
  statuses: string
  authors: string
  locations: string
}

export class GetCheckpointListQuery {
  query = ({
    limit,
    offset,
    orderBy,
    search,
    project,
    dateStart,
    dateEnd,
    authors,
    statuses,
    locations
  }: Partial<QueryParams>): string => {
    const queryString = QueryString.stringify(snakecaseKeys({
      search,
      limit,
      offset,
      project,
      order_by: orderBy,
      access_date_start: dateStart,
      access_date_end: dateEnd,
      status: statuses,
      author: authors,
      location__in: locations
    }), { skipNull: true })

    return `checkpoints/users?${queryString}`
  }

  transformResponse = (response: { results: IUserCheckpointModel[], count: number }) => {
    const { results, count } = response

    const listOptions = results.map((user: any) =>
      new UserCheckpointAdapter(user).getFields()
    )

    return { ...response, results: listOptions, count }
  }
}