import React from 'react'
import { IIcon } from '../../../models/IIcon'

const AttachDocument:React.FC<IIcon> = ({
    width = '2.2',
    height = '2.2',
    color = '#5C5C5C',
    ...innerProps
}) => (
    <svg width={`${width}rem`} height={`${height}rem`} {...innerProps} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_8008_8775)">
            <path d="M16.9583 14.6667H6.41667C4.39083 14.6667 2.75 13.0258 2.75 11C2.75 8.97418 4.39083 7.33334 6.41667 7.33334H17.875C19.14 7.33334 20.1667 8.36001 20.1667 9.62501C20.1667 10.89 19.14 11.9167 17.875 11.9167H8.25C7.74583 11.9167 7.33333 11.5042 7.33333 11C7.33333 10.4958 7.74583 10.0833 8.25 10.0833H16.9583V8.70834H8.25C6.985 8.70834 5.95833 9.73501 5.95833 11C5.95833 12.265 6.985 13.2917 8.25 13.2917H17.875C19.9008 13.2917 21.5417 11.6508 21.5417 9.62501C21.5417 7.59918 19.9008 5.95834 17.875 5.95834H6.41667C3.63 5.95834 1.375 8.21334 1.375 11C1.375 13.7867 3.63 16.0417 6.41667 16.0417H16.9583V14.6667Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M0.916016 11C0.916016 7.9602 3.37622 5.5 6.41602 5.5H17.8743C20.1533 5.5 21.9993 7.34604 21.9993 9.625C21.9993 11.904 20.1533 13.75 17.8743 13.75H8.24935C6.73122 13.75 5.49935 12.5181 5.49935 11C5.49935 9.48187 6.73122 8.25 8.24935 8.25H17.416V10.5417H8.24935C7.99831 10.5417 7.79102 10.749 7.79102 11C7.79102 11.251 7.99831 11.4583 8.24935 11.4583H17.8743C18.8862 11.4583 19.7077 10.6369 19.7077 9.625C19.7077 8.61313 18.8862 7.79167 17.8743 7.79167H6.41602C4.64331 7.79167 3.20768 9.2273 3.20768 11C3.20768 12.7727 4.64331 14.2083 6.41602 14.2083H17.416V16.5H6.41602C3.37622 16.5 0.916016 14.0398 0.916016 11ZM6.41602 6.41667C3.88248 6.41667 1.83268 8.46646 1.83268 11C1.83268 13.5335 3.88248 15.5833 6.41602 15.5833H16.4993V15.125H6.41602C4.13705 15.125 2.29102 13.279 2.29102 11C2.29102 8.72104 4.13705 6.875 6.41602 6.875H17.8743C19.3925 6.875 20.6243 8.10687 20.6243 9.625C20.6243 11.1431 19.3925 12.375 17.8743 12.375H8.24935C7.49205 12.375 6.87435 11.7573 6.87435 11C6.87435 10.2427 7.49205 9.625 8.24935 9.625H16.4993V9.16667H8.24935C7.23748 9.16667 6.41602 9.98813 6.41602 11C6.41602 12.0119 7.23748 12.8333 8.24935 12.8333H17.8743C19.6471 12.8333 21.0827 11.3977 21.0827 9.625C21.0827 7.8523 19.6471 6.41667 17.8743 6.41667H6.41602Z" fill={color}/>
        </g>
        <defs>
            <clipPath id="clip0_8008_8775">
                <rect width="22" height="22" fill="white" />
            </clipPath>
        </defs>
    </svg>

)

export default AttachDocument