import React from 'react'
import { IIcon } from '../../../models/IIcon'

const Workspaces: React.FC<IIcon> = ({
  width = '2',
  height = '2',
  color = '#949494',
  ...innerProps
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.99984 10.8333C3.1665 10.8333 1.6665 12.3333 1.6665 14.1667C1.6665 16 3.1665 17.5 4.99984 17.5C6.83317 17.5 8.33317 16 8.33317 14.1667C8.33317 12.3333 6.83317 10.8333 4.99984 10.8333ZM9.99984 2.5C8.1665 2.5 6.6665 4 6.6665 5.83333C6.6665 7.66667 8.1665 9.16667 9.99984 9.16667C11.8332 9.16667 13.3332 7.66667 13.3332 5.83333C13.3332 4 11.8332 2.5 9.99984 2.5ZM14.9998 10.8333C13.1665 10.8333 11.6665 12.3333 11.6665 14.1667C11.6665 16 13.1665 17.5 14.9998 17.5C16.8332 17.5 18.3332 16 18.3332 14.1667C18.3332 12.3333 16.8332 10.8333 14.9998 10.8333Z" fill={color} />
  </svg>
)
export default Workspaces
