import * as _ from 'lodash'
import { compose } from 'redux'

export const POSITION = /position:\[([а-я]|[А-Я]|-|\d|\w|\s)+\]/g
const POSITION_TITLE = /\[([а-я]|[А-Я]|-|\d|\w|\s)+\]/g

const COMPANY = /company:\[([а-я]|[А-Я]|\d|\w|\s)*([а-я]|[А-Я]|\d|\w|,|-|"|«|»|'|=|:|;|\s)+\]\(id:\d*\)/g
const COMPANY_TITLE = /\[([а-я]|[А-Я]|\d|\w|\s)*([а-я]|[А-Я]|\d|\w|,|-|"|«|»|'|=|:|;|\s)+\]/g

const USER = /(position:\[([а-я]|[А-Я]|-|\d|\w|\s)+\]\s)?@\[\S* \S*\.( \S*\.)*\]\(id:\d*\)/g
const USER_NAME = /([а-я]|[А-Я]|\d|\w)* ([а-я]|[А-Я]|\d|\w)*\.( ([а-я]|[А-Я]|\d|\w)*\.)*/g

const ID = /id:\d*/g
const ID_VALUE = /[0-9]+/g

const NEW_LINES = /\n/g

const config = [
  {
    match: COMPANY,
    getData: (str) => {
      const match = str.match(COMPANY)[0]
      return {
        id: match.match(ID)[0].match(ID_VALUE)[0],
        title: match.match(COMPANY_TITLE)[0].substring(1, match.match(COMPANY_TITLE)[0].length - 1),
      }
    },
    createStrFromData: (data) => (
      `<a 
        class='tag company' 
        href='${window.location.origin + `/company/${data?.id}`}' 
        target='_blank'
      >${data?.title}</a>`
    )
  },
  {
    match: USER,
    getData: (str, isEt) => {
      const match = str.match(USER)[0]
      let position = match.match(POSITION)
      if (position) {
        position = position[0].match(POSITION_TITLE)[0]
        position = position.substring(1, position.length - 1)
      }
      return {
        id: match.match(ID)[0].match(ID_VALUE)[0],
        name: match.match(USER_NAME)[0],
        position: position ?? '',
        isEt: isEt,
      }
    },
    createStrFromData: (data) => (
      //ссылка в одну строку!!! чтобы корректно работали переносы на новую строку
      data?.position
        ? `<a class='tag user-with-position' href='${window.location.origin + `/profile/${data?.id}`}' target='_blank'>${data.position} ${data.isEt ? '@' + data.name : data.name}</a>`
        : `<a class='tag' href='${window.location.origin + `/profile/${data?.id}`}' target='_blank'>${data.isEt ? '@' + data.name : data.name}</a>`
    )
  },
  {
    match: NEW_LINES,
    getData: (str) => str.replaceAll(NEW_LINES, '<br>'),
    createStrFromData: (str) => str,
  }
]
export const unparseTags = (text, isEt = true) => {
  const isNeedReplace = _.partial((str, matchObj) => str.match(matchObj.match), text)
  const replaceAccum = (str, confValue) => (
    str.replaceAll(confValue.match, (...arg) => (
      compose(confValue.createStrFromData, confValue.getData)(arg[0], isEt)
    ))
  )
  return config.filter(isNeedReplace)?.reduce(replaceAccum, text)
}
export const parseTags = (text) => {
  let resultStr = text
  const allMatches = [...text.matchAll(/\[\[\{\"id\":\"\d*\",\"value\":\"\S* \S*\.( \S*\.)*\"\}\]\]/g)]
  allMatches.forEach(matchObj => {
    const tagValue = JSON.parse(matchObj[0].substring(2, matchObj[0].length - 2))
    resultStr = resultStr.replaceAll(matchObj[0], `@[${tagValue.value}](id:${tagValue.id})`)
  })
  return resultStr
}
