import * as Sentry from '@sentry/react'
import camelcaseFields from "../../../../(deprecated)/utils/formatFields";
import { IProjectRoleRTK } from '../../../../(deprecated)/models/IProjectRole';


export class ProjectRoleAdapter {
  data: IProjectRoleRTK = {
    id: NaN,
    name: '',
    project: NaN,
    dndPosition: NaN,
    accessSubjects: {
      createDocuments: false,
      templatesManagement: false,
      readCheckpointLogs: false,
      readCheckpointDocuments: false,
      createCheckpointDocuments: false,
      createOrUpdateProject: false,
      companiesManagement: false,
      rolesManagement: false,
      userRolesManagement: false,
      locationsManagement: false,
      licensesManagement: false,
      readLicenses: false,
      restrictedAccessToSystem: false
    },
    usersCount: NaN,
    firstUsers: []
  }

  constructor(projectRole: unknown) {
    try {
      const camelCasePrepare = camelcaseFields(projectRole)
      this.data = {
        ...camelCasePrepare,
        firstUsers: camelCasePrepare.firstUsers.map((user: any) => ({
          firstName: user.firstName,
          middleName: user.middleName,
          lastName: user.lastName,
          imageUrl: user.image?.url,
          id: user.id
        }))
      }
    } catch (error) {
      // Sentry.captureMessage('Ошибка: Адаптер роли',)
    }
  }

  getFields(): IProjectRoleRTK {
    return this.data
  }
}