export const SET_LOADING_GET_ALL_COMPANIES = 'SET_LOADING_GET_ALL_COMPANIES'
export const GET_ALL_COMPANIES_SUCCESS = 'GET_ALL_COMPANIES_SUCCESS'
export const GET_ALL_COMPANIES_FAILED = 'GET_ALL_COMPANIES_FAILED'
export const CLEAR_ALL_COMPANIES_INFO = 'CLEAR_ALL_COMPANIES_INFO'

export const SET_LOADING_POST_NEW_COMPANY = 'SET_LOADING_POST_NEW_COMPANY'
export const POST_NEW_COMPANY_SUCCESS = 'POST_NEW_COMPANY_SUCCESS'
export const POST_NEW_COMPANY_FAILED = 'POST_NEW_COMPANY_FAILED'

export const SET_LOADING_GET_ACTIVE_COMPANY_ACTIVE_USER = 'SET_LOADING_GET_ACTIVE_COMPANY_ACTIVE_USER'
export const GET_ACTIVE_COMPANY_ACTIVE_USER_SUCCESS = 'GET_ACTIVE_COMPANY_ACTIVE_USER_SUCCESS'
export const GET_ACTIVE_COMPANY_ACTIVE_USER_FAILED = 'GET_ACTIVE_COMPANY_ACTIVE_USER_FAILED'

export const SET_LOADING_GET_ACTIVE_COMPANY_WORKER = 'SET_LOADING_GET_ACTIVE_COMPANY_WORKER'
export const GET_ACTIVE_COMPANY_WORKER_SUCCESS = 'GET_ACTIVE_COMPANY_WORKER_SUCCESS'
export const GET_ACTIVE_COMPANY_WORKER_FAILED = 'GET_ACTIVE_COMPANY_WORKER_FAILED'

export const POST_COMPANY_BY_INN_SUCCESS = 'POST_COMPANY_BY_INN_SUCCESS'
export const POST_COMPANY_BY_INN_FAILED = 'POST_COMPANY_BY_INN_FAILED'
export const POST_COMPANY_BY_INN_RESET = 'POST_COMPANY_BY_INN_RESET'
export const POST_TOGGLE_LOADING_BY_INN = 'POST_TOGGLE_LOADING_BY_INN'
export const CLEAR_COMPANY_BY_INN = 'CLEAR_COMPANY_BY_INN'

export const GET_LINK_FOR_TEMPLATE_SUCCESS = 'GET_LINK_FOR_TEMPLATE_SUCCESS'
export const GET_TOGGLE_LOADING_LINK_TEMPLATE = 'GET_TOGGLE_LOADING_LINK_TEMPLATE'

export const DELETE_COMPANY_IN_ALLCOMPANIES = 'DELETE_COMPANY_IN_ALLCOMPANIES'
export const SET_HAS_BEEN_FETCHED = 'SET_HAS_BEEN_FETCHED'

export const SET_LOADING_UPLOAD_COMPANY_FROM_EXCEL = 'SET_LOADING_UPLOAD_COMPANY_FROM_EXCEL'