import React from 'react'
import { IIcon } from '../../../models/IIcon'

const AlignRightEditor: React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  ...innerProps
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 21H21V19H3V21ZM9 17H21V15H9V17ZM3 13H21V11H3V13ZM9 9H21V7H9V9ZM3 3V5H21V3H3Z" fill="#5C5C5C" />
  </svg>
)
export default AlignRightEditor