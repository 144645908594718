import QueryString from 'query-string'
import { CheckpointLogAdapter, ICheckpointLogModel } from './checkpointLogsAdapter'

type QueryParams = {
  orderBy: string
  project: number
  number: string
  limit: number
  offset: number
}

export class GetCheckpointVehiclesLogs {
  query = ({
    orderBy,
    number,
    project,
    limit,
    offset,
  }: Partial<QueryParams>): string => {
    const queryString = QueryString.stringify({
      number,
      limit,
      offset,
      order_by: orderBy,
    }, { skipNull: true })

    return `checkpoints/${project}/vehicles/log?${queryString}`
  }

  transformResponse = (response: { results: ICheckpointLogModel[], count: number }) => {
    const { results, count } = response

    const listOptions = results.map((item: any) =>
      new CheckpointLogAdapter(item).getFields()
    )

    return { ...response, results: listOptions, count }
  }
}