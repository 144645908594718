import React from 'react'
import { IIcon } from '../../../models/IIcon'

const AddDocument: React.FC<IIcon> = ({
  width = '2.5',
  height = '2.4',
  color = 'white',
  ...innerProps
}) => {
  return (
    <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 4.075C12.7333 4.075 12.9375 3.9875 13.1125 3.8125C13.2875 3.6375 13.375 3.43333 13.375 3.2C13.375 2.96667 13.2875 2.7625 13.1125 2.5875C12.9375 2.4125 12.7333 2.325 12.5 2.325C12.2667 2.325 12.0625 2.4125 11.8875 2.5875C11.7125 2.7625 11.625 2.96667 11.625 3.2C11.625 3.43333 11.7125 3.6375 11.8875 3.8125C12.0625 3.9875 12.2667 4.075 12.5 4.075ZM18.825 22.975C17.525 22.975 16.4167 22.5125 15.5 21.5875C14.5833 20.6625 14.125 19.5667 14.125 18.3C14.125 17 14.5833 15.8875 15.5 14.9625C16.4167 14.0375 17.525 13.575 18.825 13.575C20.1083 13.575 21.2125 14.0375 22.1375 14.9625C23.0625 15.8875 23.525 17 23.525 18.3C23.525 19.5667 23.0625 20.6625 22.1375 21.5875C21.2125 22.5125 20.1083 22.975 18.825 22.975ZM18.45 21.475H19.275V18.725H22.025V17.9H19.275V15.15H18.45V17.9H15.7V18.725H18.45V21.475ZM7.5 8.5H17.5V7H7.5V8.5ZM13.25 21H5C4.58333 21 4.22917 20.8542 3.9375 20.5625C3.64583 20.2708 3.5 19.9167 3.5 19.5V4.5C3.5 4.08333 3.64583 3.72917 3.9375 3.4375C4.22917 3.14583 4.58333 3 5 3H10.125C10.2083 2.41667 10.475 1.9375 10.925 1.5625C11.375 1.1875 11.9 1 12.5 1C13.1 1 13.625 1.1875 14.075 1.5625C14.525 1.9375 14.7917 2.41667 14.875 3H20C20.4167 3 20.7708 3.14583 21.0625 3.4375C21.3542 3.72917 21.5 4.08333 21.5 4.5V12.725C21.0833 12.5083 20.65 12.35 20.2 12.25C19.75 12.15 19.2917 12.1 18.825 12.1C18.5917 12.1 18.3667 12.1125 18.15 12.1375C17.9333 12.1625 17.7167 12.2 17.5 12.25V11.25H7.5V12.75H16.1C15.5 13.05 14.9625 13.4375 14.4875 13.9125C14.0125 14.3875 13.625 14.9167 13.325 15.5H7.5V17H12.775C12.725 17.2167 12.6875 17.4333 12.6625 17.65C12.6375 17.8667 12.625 18.0917 12.625 18.325C12.625 18.8083 12.675 19.2667 12.775 19.7C12.875 20.1333 13.0333 20.5667 13.25 21Z" fill={color} />
    </svg>
  )
}

export default AddDocument