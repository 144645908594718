import React, { useState } from 'react'
import { useTDispatch, useTSelector } from '../../../../utils/hooks/reduxHooks'
import { Link } from 'react-router-dom'
import { amplitudeLogEvent } from '../../../../amplitude'
import { AMPLITUDE_EVENTS } from '../../../../amplitude/events'
import { markAsReadNotifications, readAllAppNotifications } from '../../../../redux/appNotification/actionCreator'
import AppNotificationList from '../../../UI/AppNotificationList/AppNotificationList'
import cn from 'classnames'
import * as Icons from '../../../UI/icons'

export const LeftSidebarHeader = () => {
  const dispatch = useTDispatch()

  const [isOpenAppNotification, setIsOpenAppNotification] = useState(false)
  
  const unreadNotificationsCount = useTSelector((state: any) => state.appNotification.unreadNotificationsCount) as null | number
  const userInfo = useTSelector(state => state.user.userInfo)

  return (
    <>
      <AppNotificationList
        visible={isOpenAppNotification}
        onClose={() => setIsOpenAppNotification(false)}
      />
      <div className='sidebar_top__main'>
        <Link className={cn('sidebar-logo', {
          'sidebar__logo_have-notification': !!unreadNotificationsCount
        })} to='/'>
          <Icons.Logo/>
          {/* <img src={logo} alt='Simple Solution' /> */}
        </Link>
        <div className='sidebar_top__notification'>
          {!!unreadNotificationsCount && (
            <div className='sidebar_top__notification-count'>{unreadNotificationsCount}</div>
          )}
          <Icons.NotificationEventIcon
            className={cn('btn', 'btn_hover', {
              'btn_active': isOpenAppNotification,
            })}
            onClick={() => {
              amplitudeLogEvent({
                event: isOpenAppNotification 
                  ? AMPLITUDE_EVENTS.LEFT_SIDEBAR_CLOSE_NOTIFICATIONS_BTN_CLICK
                  : AMPLITUDE_EVENTS.LEFT_SIDEBAR_OPEN_NOTIFICATIONS_BTN_CLICK,
                options: userInfo
              })
              if (isOpenAppNotification) {
                dispatch(markAsReadNotifications())
                dispatch(readAllAppNotifications())
              }
              setIsOpenAppNotification(state => !state)
            }}
          />
        </div>
      </div>
    </>
  )
}

