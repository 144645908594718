export const getFullName = ({ firstName = '', middleName = '', lastName = '' } = {}) =>
  [lastName, firstName, middleName].filter(Boolean).join(' ') ?? ''

export const getShortName = (arg: ShortNameArg) => {
  if (typeof arg === 'string') {

    return arg
      .split(' ')
      .map((partName, i) => {
        if (i === 0)
          return partName + ' '
        return partName[0] + '. '
      })
      .join('')
  }

  if (arg instanceof Object) {
    const { lastName, firstName, middleName } = arg

    if (!lastName) return [firstName, middleName].filter(Boolean).join(' ') || ''
    if (!firstName) return lastName || ''
  
    const lastNameAndShortFirstName = `${[lastName, firstName[0]].join(' ')}.`
    return middleName
      ? `${lastNameAndShortFirstName} ${middleName[0]}.`
      : lastNameAndShortFirstName
  }
}

type ShortNameArg = string | { 
  firstName: string 
  middleName: string 
  lastName: string 
}