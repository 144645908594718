import React from 'react'
import { ACCESS_SUBJECT, ACCESS_SUBJECT_BY_MODULE, ACCESS_SUBJECT_BY_MODULE_KEY, sequenceKeyInMatrix } from '../../constant/accessSubject'

import cn from 'classnames'

import './AccessSubjectBar.scss'

//TODO перенести
import * as Icons from '../../../../../(deprecated)/components/UI/icons'

export const AccessSubjectBar: React.FC = () => {

  const getIconModule = (module: ACCESS_SUBJECT_BY_MODULE_KEY) => {

    if (module == 'docs')
      return <Icons.DocumentsMenu color='#5C5C5C' className='module-icon' />

    if (module == 'checkpoint')
      return <Icons.Gate color='#5C5C5C' className='module-icon' />

    if (module == 'project')
      return <Icons.Project color='#5C5C5C' className='module-icon' />

    return <></>
  }

  return (
    <div className='settings-project-role-access-subject-bar'>
      <div className='settings-project-role-access-subject-bar modules'>
        {(Object.keys(ACCESS_SUBJECT_BY_MODULE) as Array<keyof typeof ACCESS_SUBJECT_BY_MODULE>).map((module) => (
          <div
            className={cn('modules__item', module)}
            style={{ height: `${ACCESS_SUBJECT_BY_MODULE[module].height}rem` }}
          >
            <p className='module-title'>{ACCESS_SUBJECT_BY_MODULE[module]?.title}</p>
            {getIconModule(module)}
          </div>
        ))}
      </div>
      <div className='settings-project-role-access-subject-bar access-subjects'>
        {sequenceKeyInMatrix.map((key) => (
          <div
            className='access-subjects__item'
            style={{ height: `${ACCESS_SUBJECT[key].height}rem` }}
          >
            <p>{ACCESS_SUBJECT[key].name}</p>
          </div>
        ))}
      </div>
    </div>
  )
}