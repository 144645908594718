import React from 'react'
import { IIcon } from '../../../models/IIcon'

const TextField: React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  ...innerProps
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.5 4V7H7.5V19H10.5V7H15.5V4H2.5ZM21.5 9H12.5V12H15.5V19H18.5V12H21.5V9Z" fill="#5C5C5C" />
  </svg>
)
export default TextField