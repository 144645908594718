export const SET_LOADING_GET_COMPANY = 'SET_LOADING_COMPANY'
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS'
export const GET_COMPANY_FAILED = 'GET_COMPANY_FAILED'
export const POST_COMPANY_DOCUMENT_SUCCESS = 'POST_COMPANY_DOCUMENT_SUCCESS'

export const SET_LOADING_PUT_COMPANY = 'SET_LOADING_PUT_COMPANY'
export const PUT_COMPANY_SUCCESS = 'PUT_COMPANY_SUCCESS'
export const PUT_COMPANY_FAILED = 'PUT_COMPANY_FAILED'

export const SET_LOADING_POST_WORKER = 'SET_LOADING_POST_WORKER'
export const POST_WORKER_SUCCESS = 'POST_WORKER_SUCCESS'
export const POST_WORKER_FAILED = 'POST_WORKER_FAILED'

export const SET_LOADING_GET_ACTIVE_USER = 'SET_LOADING_GET_ACTIVE_USER'
export const GET_ACTIVE_USER_SUCCESS = 'GET_ACTIVE_USER_SUCCESS'
export const GET_ACTIVE_USER_FAILED = 'GET_ACTIVE_USER_FAILED'

export const SET_LOADING_GET_WORKER = 'SET_LOADING_GET_WORKER'
export const GET_WORKER_SUCCESS = 'GET_WORKER_SUCCESS'
export const GET_WORKER_FAILED = 'GET_WORKER_FAILED'

export const SET_LOADING_PUT_WORKER_INFO = 'SET_LOADING_PUT_WORKER_INFO'
export const PUT_WORKER_INFO_SUCCESS = 'PUT_WORKER_INFO_SUCCESS'
export const PUT_WORKER_INFO_FAILED = 'PUT_WORKER_INFO_FAILED'

export const SET_LOADING_POST_WORKER_FROM_EXCEL = 'SET_LOADING_POST_WORKER_FROM_EXCEL'
export const POST_WORKER_FROM_EXCEL_SUCCESS = 'POST_WORKER_FROM_EXCEL_SUCCESS'
export const POST_WORKER_FROM_EXCEL_FAILED = 'POST_WORKER_FROM_EXCEL_FAILED'

export const GET_LINK_FOR_QR_CODE = 'GET_LINK_FOR_QR_CODE'

export const DELETE_COMPANY = 'DELETE_COMPANY'
export const DELETE_USER = 'DELETE_USER'
export const DELETE_WORKER = 'DELETE_WORKER'

export const  SET_IS_ALL_USERS_LOADING = 'SET_IS_ALL_USERS_LOADING'
export const  GET_IS_ALL_USERS_SUCCESS = "GET_IS_ALL_USERS_SUCCESS"

export const CLEAR_ALL_USERS = 'CLEAR_ALL_USERS'

export const SET_LOADING_ADD_USER = 'SET_LOADING_ADD_USER'

export const SET_LOADING_COMPANY_QRS = 'SET_LOADING_COMPANY_QRS'

export const SET_LOADING_CHECKPOINTS_AUTHORS_LOADING = 'SET_LOADING_CHECKPOINTS_LOADING'
export const SET_CHECKPOINTS_AUTHORS = 'SET_CHECKPOINTS_AUTHORS'