import React from 'react'
import { IIcon } from '../../../models/IIcon'

const FilterIconInCircle: React.FC<IIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#1A65DA',
  ...innerProps
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 187 177" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.165039" y="11.272" width="164.998" height="164.998" rx="82.4989" fill="#E8F1FB"/>
    <path d="M138.519 56.1878C138.19 55.4263 137.644 54.7789 136.948 54.3269C136.253 53.8748 135.439 53.6382 134.61 53.6467H72.0602C71.2341 53.6468 70.4259 53.8868 69.7336 54.3375C69.0413 54.7883 68.4948 55.4304 68.1606 56.1859C67.8263 56.9413 67.7187 57.7776 67.8507 58.5931C67.9827 59.4085 68.3488 60.1681 68.9043 60.7795L68.9221 60.7973L92.993 86.4959C93.2402 86.7599 93.3774 87.1081 93.3769 87.4697V114.775C93.3757 115.547 93.5845 116.306 93.9808 116.969C94.3771 117.632 94.9462 118.175 95.6272 118.54C96.3081 118.905 97.0754 119.078 97.8471 119.041C98.6188 119.004 99.3659 118.758 100.009 118.329L111.381 110.748C111.967 110.358 112.447 109.83 112.778 109.21C113.11 108.59 113.283 107.897 113.283 107.194V87.4697C113.282 87.1081 113.419 86.7599 113.666 86.4959L137.755 60.7795C138.32 60.174 138.693 59.4155 138.829 58.5988C138.965 57.7821 138.857 56.9435 138.519 56.1878ZM135.676 58.8533L111.594 84.5661C110.854 85.3571 110.442 86.4004 110.443 87.4839V107.205C110.443 107.439 110.385 107.669 110.275 107.876C110.165 108.082 110.005 108.258 109.81 108.388L98.4377 115.969C98.2228 116.112 97.9729 116.194 97.7148 116.206C97.4568 116.218 97.2003 116.16 96.973 116.037C96.7457 115.914 96.5562 115.732 96.4248 115.51C96.2934 115.287 96.225 115.033 96.2271 114.775V87.4697C96.2279 86.3862 95.8162 85.3429 95.0757 84.5519L71.0118 58.8604C70.8291 58.6566 70.7091 58.4044 70.6663 58.134C70.6234 57.8637 70.6595 57.5867 70.7702 57.3364C70.8809 57.0861 71.0615 56.8731 71.2904 56.7229C71.5192 56.5727 71.7865 56.4918 72.0602 56.4899H134.61C134.884 56.4896 135.152 56.5685 135.382 56.717C135.612 56.8654 135.794 57.0772 135.907 57.3269C136.02 57.5765 136.058 57.8534 136.017 58.1241C135.976 58.3949 135.858 58.6481 135.676 58.8533Z" fill="#1A65DA"/>
    <rect x="20.835" y="1" width="165" height="165" rx="82.5" stroke="#1A65DA" strokeWidth="2" strokeLinecap="round" strokeDasharray="10 15"/>
  </svg>
)

export default FilterIconInCircle