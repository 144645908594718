import { IDoc, IDocAction, IDocTableAction } from "../../../../models/IDocs";

const comparator = (a: IDocTableAction, b: IDocTableAction) => {
  const first = Number(a.cssSelector.replace(/\d+-signer-/, '').replace('-', '.'))
  const second = Number(b.cssSelector.replace(/\d+-signer-/, '').replace('-', '.'))
  return first - second
}

export const getAllAction = (doc: IDoc) => (
  doc?.actions.reduce((accum, action) => (
    action.allTableActions
      ? [...accum, ...[...action.allTableActions].map((tAction) => ({
        ...tAction,
        title: action.title,
        baseAction: action.activeTableActions![0]?.baseAction
      })).sort(comparator)]
      : [...accum, action]
  ), [] as (IDocAction | IDocTableAction)[])
)
