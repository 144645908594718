import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { formatDateFromString } from '../../../utils/dateUtils'
import { clearAppNotifications, clearAppNotificationsForShow, getNotifications, markAsReadNotifications, readAllAppNotifications, setToggleLoadingNotification } from '../../../redux/appNotification/actionCreator'
import { useIntersectionObserver } from '../../../utils/hooks/useIntersectionObserver'
import { addedNewNotification } from '../../../redux/notifications/actions'
import { INotification } from '../../../models/INotification'
import { settingActiveProject } from '../../../redux/project/actions'
import { setBacklightForDocShort } from '../../../../fsd/shared/reducers/docsShort/backlightForDocsShort/slice'

import AppNotificationCard from './AppNotificationCard/AppNotificationCard'
import PopUp from '../PopUp/PopUp'
import Loader from '../Loader/Loader'
import * as Icon from '../icons'
import cn from 'classnames'
import queryString from 'query-string'

import './AppNotificationList.scss'

const AppNotificationList: React.FC<AppNotificationListTypeComponent> = ({
  visible,
  onClose,
}) => {

  const dispatch = useDispatch()
  const history = useHistory()
  const [refObserve, isVisibleObserve] = useIntersectionObserver({
    root: null,
    rootMargin: '0px',
    threshold: 0.8
  })

  // Selectors
  const notificationsConnections: boolean = useSelector((state: any) => state.appNotification.notificationsConnections)
  const allNotifications: INotification[] = useSelector((state: any) => state.appNotification.allNotifications)
  const unreadNotificationsCount: number | null = useSelector((state: any) => state.appNotification.unreadNotificationsCount)
  const isLoadingNotifications: boolean = useSelector((state: any) => state.appNotification.isLoadingNotifications)
  const totalPages: number | null = useSelector((state: any) => state.appNotification.totalPages)
  const notificationsForShow: INotification[] = useSelector((state: any) => state.appNotification.notificationsForShow)

  // InnerState
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [prevValueNotConnection, setPrevValueNotConnection] = useState<boolean[]>([false])

  // Handlers
  const handleClose = () => {
    if (visible) {
      dispatch(markAsReadNotifications())
      dispatch(readAllAppNotifications())
      onClose()
    }
  }


  const handleNotificationClick = useCallback((data: INotification) => {

    //  TODO переделать!
    //  Обработка нотификации, когда изменили доступ к нескольким документам
    if (data.notificationType == 'docflow' && data.title == 'Изменение в доступе к документу') {

      const parsedUrl = queryString.parseUrl(data.url)

      if (typeof parsedUrl.query.project_id == 'string') {

        dispatch(settingActiveProject(Number(parsedUrl.query.project_id)))
        dispatch(setBacklightForDocShort(data.additionalInfo))

        dispatchEvent(new Event('update-docsShort'))

        !history.location.pathname.includes('/documents/incoming') &&
          history.push('/documents/incoming')

        return
      }

    }

    if (data.url.length) {
      const urlObject = new URL(data.url)

      const pathName = urlObject.pathname
        .replace('all/', '')
        .replace(/\/project\/\d{1}/, '')

      // todo убрать replace полсе изменений на беке
      history.push(pathName || '/')

      dispatchEvent(new Event('openDocDiscussion'))
    }
  }, [])

  // Common
  const preparationNotifications = (notifications: INotification[]) => (
    notifications.reduce((res: { [key: string]: INotification[] }, notification: any) => {
      const key = formatDateFromString.toStandartDate(Date.parse(notification.sentTime))
      return res[key]
        ? { ...res, [key]: [...res[key], notification] }
        : { ...res, [key]: [notification] }
    }, {})
  )

  // Template
  const renderNotification = (preparationNotification: { [key: string]: INotification[] }) => (
    Object.keys(preparationNotification).map(date => (
      <React.Fragment key={`${date}`}>
        <div className='app-notifications__date'>
          {formatDateFromString.toStandartDayAndMoutn(date)}
        </div>
        {preparationNotification[date].map((notification) => (
          <AppNotificationCard
            key={notification.id}
            notificationData={notification}
            handleNotificationClick={handleNotificationClick}
          />
        ))}
      </React.Fragment>
    ))
  )

  //useEffect 
  useEffect(() => {
    if (notificationsForShow.length) {
      notificationsForShow.forEach(notification => (
        dispatch(addedNewNotification({ ...notification, message: notification?.body, header: notification?.header }))
      ))
      dispatch(clearAppNotificationsForShow())
    }
  }, [notificationsForShow])

  useEffect(() => {
    if (isVisibleObserve && !isLoadingNotifications)
      totalPages && (totalPages >= currentPage + 1) && setCurrentPage(state => state + 1)
  }, [isVisibleObserve])

  useEffect(() => {
    if (notificationsConnections && currentPage > 1) {
      dispatch(setToggleLoadingNotification())
      dispatch(getNotifications(currentPage))
    }
  }, [currentPage, notificationsConnections])

  useEffect(() => {
    visible && !allNotifications.length && dispatch(getNotifications())
  }, [visible, allNotifications])

  useEffect(() => {
    setPrevValueNotConnection(state => [...state, notificationsConnections])
  }, [notificationsConnections])

  useEffect(() => {
    const isFetchNotifications = prevValueNotConnection.length >= 2 && prevValueNotConnection[prevValueNotConnection.length - 2] == false && prevValueNotConnection[prevValueNotConnection.length - 1] == true
    if (isFetchNotifications) {
      dispatch(clearAppNotifications())
      dispatch(getNotifications())
      setCurrentPage(1)
    }
  }, [prevValueNotConnection])

  return (
    <PopUp
      onClose={handleClose}
      className={cn('app-notifications', { 'hidden': !visible })}
      otherClass={['.sidebar_top__notification']}
    >
      <div className='app-notifications__header'>
        <h3>Оповещения</h3>
        <div className='count-new-notification'>
          {unreadNotificationsCount} новых
          <Icon.NewNotification />
        </div>
      </div>
      <div className='app-notifications__body'>
        {renderNotification(preparationNotifications(allNotifications))}
        {isLoadingNotifications && <Loader />}
        <div ref={refObserve} style={{ opacity: 0, height: '1rem', width: '100%' }}></div>
      </div>
    </PopUp>
  )
}

export default AppNotificationList


type AppNotificationListTypeComponent = {
  visible: boolean,
  onClose: () => void,
}