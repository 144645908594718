import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { amplitudeLogEvent } from '../../../../amplitude'
import { useTSelector } from '../../../../utils/hooks/reduxHooks'
import { AMPLITUDE_EVENTS } from '../../../../amplitude/events'
import setToggleSidebar from '../../../../redux/app/actions'
import cn from 'classnames'
import PropTypes from 'prop-types'
import * as Icon from '../../icons/index'

import './SidebarWrapper.scss'

const SidebarWrapper = ({ component }) => {
  const dispatch = useDispatch()

  const { isOpenedLeftSidebar } = useSelector(state => state.app)
  const mobileInfo = useSelector((state) => state.app.mobileInfo)
  const userInfo = useTSelector(state => state.user.userInfo)

  const refSideBar = useRef()

  useEffect(() => {
    if (mobileInfo.width)
      refSideBar.current.style.height = `${mobileInfo.width / 0.8}px` // это не ошибка
  }, [mobileInfo.width])

  return (
    <div
      className={cn('wrapper-sidebar', { 'wrapper-sidebar_open': isOpenedLeftSidebar })}
      ref={refSideBar}
    >
      {{
        ...component,
        props: {
          ...component.props,
          isOpenSidebar: isOpenedLeftSidebar,
          setIsOpenSidebar: () => dispatch(setToggleSidebar())
        }
      }}
      <div
        onClick={() => {
          amplitudeLogEvent({ 
            options: userInfo,
            event: isOpenedLeftSidebar 
              ? AMPLITUDE_EVENTS.LEFT_SIDEBAR_BTN_CLOSE_CLICK 
              : AMPLITUDE_EVENTS.LEFT_SIDEBAR_BTN_OPEN_CLICK    
          })
          dispatch(setToggleSidebar())
        }}
        className='wrapper-sidebar__open-btn'
      >
        <Icon.ChevronWithoutBorder />
      </div>
    </div>
  )
}

SidebarWrapper.propTypes = {
  component: PropTypes.element,
}

export default SidebarWrapper