import React from 'react'
import { IIcon } from '../../../models/IIcon'

const CommentPlaceholder: React.FC<IIcon> = ({
  width = '5.8',
  height = '5.6',
  color = '#FFFFFF',
  ...innerProps
}) => (
  <svg width={`${width}rem`} height={`${height}rem`} {...innerProps} viewBox="0 0 58 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.083 1.08333C1.97844 1.08333 1.08301 1.97876 1.08301 3.08333V52.0882C1.08301 53.8701 3.23729 54.7624 4.49722 53.5025L11.6639 46.3358C12.039 45.9607 12.5477 45.75 13.0781 45.75H54.9163C56.0209 45.75 56.9163 44.8546 56.9163 43.75V3.08333C56.9163 1.97876 56.0209 1.08333 54.9163 1.08333H3.083ZM48.5413 28.2593C48.5413 29.8139 46.8454 30.7741 45.5124 29.9743L42.5912 28.2216C41.9578 27.8415 41.1665 27.8415 40.5332 28.2216L37.612 29.9743C36.279 30.7741 34.583 29.8139 34.583 28.2593V11.4583C34.583 10.3538 35.4784 9.45833 36.583 9.45833H46.5413C47.6459 9.45833 48.5413 10.3538 48.5413 11.4583V28.2593Z" stroke="#747678" strokeWidth="2"/>
  </svg>
)
export default CommentPlaceholder;