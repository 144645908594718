import React, { useState } from 'react'
import { PortalWrapper } from '../../../../../fsd/shared/ui-component'
import { useTSelector } from '../../../../utils/hooks/reduxHooks'
import { SupportInfo } from './ui/SupportInfo'

import useHandleClickOutside from '../../../../utils/hooks/useHandleClickOutside'
import * as Icons from '../../../../../fsd/shared/ui-icons'
import cn from 'classnames'

import './LeftSidebarSupport.scss'

export const LeftSidebarSupport = () => {
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const isOpenLeftSidebar = useTSelector(state => state.app.isOpenedLeftSidebar)

  useHandleClickOutside(['.left-sidebar-support', '.left-sidebar-support-popup'], () => setIsOpenPopup(false))

  return (
    <>
      <div
        className={cn('left-sidebar-support', {
          'close': !isOpenLeftSidebar
        })}
        onClick={() => !isOpenLeftSidebar && setIsOpenPopup(isOpen => !isOpen)}
      >
        <div className='left-sidebar-support-content'>
          <Icons.SupportPersonHead
            color={isOpenPopup ? '#1a65da' : '#5C5C5C'}
            className='support-icon'
          />
          <div className='support-info'>
            <SupportInfo />
          </div>
        </div>
      </div>

      <PortalWrapper parentNodeSelector='.main-layout'>
        <div className={cn('left-sidebar-support-popup', {
          open: isOpenPopup && !isOpenLeftSidebar
        })}>
          <SupportInfo />
        </div>
      </PortalWrapper>
    </>
  )
}