import React from 'react'
import { IIcon } from '../../../models/IIcon'

const Digits: React.FC<IIcon> = ({
  width = '2.1',
  height = '1',
  color = '#C2C2C2',
  ...innerProps
}) => (
<svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 21 10" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4.26759 9.833H2.40549V2.75885C1.72515 3.40013 0.92332 3.87442 0 4.1817V2.47829C0.485958 2.31797 1.01388 2.01514 1.58378 1.56981C2.15368 1.12002 2.54465 0.596749 2.75671 0H4.26759V9.833Z" fill="#5C5C5C"/>
  <path d="M13.3462 8.08951V9.833H6.81887C6.88956 9.1739 7.10161 8.55043 7.45503 7.96259C7.80846 7.3703 8.50647 6.58651 9.54907 5.61122C10.3885 4.82298 10.9031 4.28858 11.0931 4.00802C11.3493 3.62057 11.4774 3.23759 11.4774 2.85905C11.4774 2.44044 11.3648 2.1198 11.1395 1.89713C10.9186 1.67001 10.6115 1.55645 10.2184 1.55645C9.8296 1.55645 9.52035 1.67446 9.29063 1.91049C9.0609 2.14652 8.92837 2.53841 8.89303 3.08617L7.03755 2.89913C7.148 1.86595 7.49479 1.12447 8.07794 0.674683C8.66109 0.224894 9.39003 0 10.2648 0C11.2234 0 11.9766 0.260521 12.5245 0.781563C13.0723 1.30261 13.3462 1.95057 13.3462 2.72545C13.3462 3.16633 13.2666 3.58717 13.1076 3.98798C12.953 4.38432 12.7056 4.80071 12.3654 5.23714C12.1401 5.52661 11.7337 5.943 11.1461 6.48631C10.5585 7.02961 10.1852 7.39034 10.0262 7.56847C9.87157 7.7466 9.74566 7.92029 9.64847 8.08951H13.3462Z" fill="#5C5C5C"/>
  <path d="M14.5456 7.23447L16.3481 7.01403C16.4055 7.47718 16.5601 7.83122 16.8119 8.07615C17.0637 8.32109 17.3686 8.44355 17.7264 8.44355C18.1108 8.44355 18.4333 8.29659 18.6939 8.00267C18.959 7.70875 19.0915 7.3124 19.0915 6.81363C19.0915 6.34157 18.9656 5.96749 18.7138 5.69138C18.462 5.41528 18.1549 5.27722 17.7927 5.27722C17.5541 5.27722 17.2692 5.32398 16.9378 5.4175L17.1433 3.88778C17.6469 3.90114 18.0312 3.79203 18.2963 3.56045C18.5614 3.32443 18.6939 3.01269 18.6939 2.62525C18.6939 2.2957 18.5967 2.03295 18.4023 1.83701C18.208 1.64106 17.9495 1.54309 17.627 1.54309C17.3089 1.54309 17.0372 1.65442 16.8119 1.87709C16.5866 2.09976 16.4497 2.42485 16.4011 2.85237L14.6848 2.55845C14.804 1.96615 14.983 1.4941 15.2215 1.14228C15.4645 0.786017 15.8003 0.507682 16.2288 0.307281C16.6617 0.102427 17.1455 0 17.68 0C18.5945 0 19.3279 0.293921 19.8801 0.881764C20.3351 1.36273 20.5626 1.90603 20.5626 2.51169C20.5626 3.37119 20.0966 4.057 19.1644 4.56914C19.721 4.68938 20.165 4.95881 20.4964 5.37742C20.8321 5.79604 21 6.30149 21 6.89379C21 7.75328 20.6885 8.48586 20.0656 9.09152C19.4427 9.69717 18.6674 10 17.7397 10C16.8605 10 16.1316 9.74616 15.5529 9.23848C14.9741 8.72634 14.6384 8.05834 14.5456 7.23447Z" fill="#5C5C5C"/>
</svg>
)

export default Digits