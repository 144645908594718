import React from 'react'

const Image = ({ width = '24', height = '24', color = 'black', opacity = 0.48, ...innerProps }) => (
  <svg {...innerProps} width={width} height={height} viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7 4C5.34315 4 4 5.34315 4 7C4 8.65685 5.34315 10 7 10C8.65685 10 10 8.65685 10 7C10 5.34315 8.65685 4 7 4ZM6 7C6 6.44772 6.44772 6 7 6C7.55228 6 8 6.44772 8 7C8 7.55228 7.55228 8 7 8C6.44772 8 6 7.55228 6 7Z" fill={color} fillOpacity={opacity}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M3 0C1.34315 0 0 1.34315 0 3V15C0 16.6569 1.34315 18 3 18H21C22.6569 18 24 16.6569 24 15V3C24 1.34315 22.6569 0 21 0H3ZM21 2H3C2.44772 2 2 2.44772 2 3V15C2 15.5523 2.44772 16 3 16H7.31374L14.1924 9.12136C15.364 7.94978 17.2635 7.94978 18.435 9.12136L22 12.6863V3C22 2.44772 21.5523 2 21 2ZM21 16H10.1422L15.6066 10.5356C15.9971 10.145 16.6303 10.145 17.0208 10.5356L21.907 15.4217C21.7479 15.7633 21.4016 16 21 16Z" fill={color} fillOpacity={opacity}/>
  </svg>
)

export default Image
