import React from 'react'
import { IIcon } from '../../../models/IIcon'

const UndoEditor: React.FC<IIcon> = ({
  width = '2.4',
  height = '2.0',
  ...innerProps
}) => (
  <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.4913 15.5162L15.9313 20L16.003 19.601C17.0589 13.7215 12.231 8.4526 6.2964 9.00777L6.03459 9.03226V12.0968L0 6.04839L6.03459 0V3.30645L9.54727 3.52765C15.502 3.90262 19.4559 9.86955 17.4913 15.5162Z" fill="#5C5C5C" />
  </svg>
)

export default UndoEditor