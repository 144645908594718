import { IProjectCompany } from '../../../models/ICompany'
import camelcaseFields from '../../../utils/formatFields'

export type ProjectCompanyModel = {
  project: number
  id: number
  title: string
  type: string
  imageUrl: string
  email: string
  isAccepted: boolean
  isProjectOwner: boolean
  phone: string
}

export class ProjectCompanyAdapter {
  private model: ProjectCompanyModel = {
    id: NaN,
    title: '',
    type: '',
    imageUrl: '',
    email: '',
    project: NaN,
    isAccepted: false,
    isProjectOwner: false,
    phone: ''
  }

  constructor(projectCompany: IProjectCompany) {
    try {
      const validCompany = camelcaseFields(projectCompany)

      this.model.id = validCompany.company.id
      this.model.title = validCompany.company.title
      this.model.type = validCompany.company.type
      this.model.imageUrl = validCompany.company.image?.url
      this.model.email = validCompany.company.email
      this.model.project = validCompany.project
      this.model.isAccepted = validCompany.isAccepted
      this.model.isProjectOwner = validCompany.isProjectOwner
    } catch (e) {
      console.error(e)
    }
  }

  getFields() {
    return this.model
  }
}