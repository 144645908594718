import React, { CSSProperties } from 'react'
import { IIcon } from '../../../models/IIcon'

interface IDocTabletIcon extends IIcon {
  style?: CSSProperties
}

const DocumentTablet: React.FC<IDocTabletIcon> = ({
  width = '2.4',
  height = '2.4',
  color = '#5C5C5C',
  ...innerProps
}) => (
  <svg width={`${width}rem`} height={`${height}rem`} {...innerProps} viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 3.075C9.23333 3.075 9.4375 2.9875 9.6125 2.8125C9.7875 2.6375 9.875 2.43333 9.875 2.2C9.875 1.96667 9.7875 1.7625 9.6125 1.5875C9.4375 1.4125 9.23333 1.325 9 1.325C8.76667 1.325 8.5625 1.4125 8.3875 1.5875C8.2125 1.7625 8.125 1.96667 8.125 2.2C8.125 2.43333 8.2125 2.6375 8.3875 2.8125C8.5625 2.9875 8.76667 3.075 9 3.075ZM15.325 21.975C14.025 21.975 12.9167 21.5125 12 20.5875C11.0833 19.6625 10.625 18.5667 10.625 17.3C10.625 16 11.0833 14.8875 12 13.9625C12.9167 13.0375 14.025 12.575 15.325 12.575C16.6083 12.575 17.7125 13.0375 18.6375 13.9625C19.5625 14.8875 20.025 16 20.025 17.3C20.025 18.5667 19.5625 19.6625 18.6375 20.5875C17.7125 21.5125 16.6083 21.975 15.325 21.975ZM14.95 20.475H15.775V17.725H18.525V16.9H15.775V14.15H14.95V16.9H12.2V17.725H14.95V20.475ZM4 7.5H14V6H4V7.5ZM9.75 20H1.5C1.08333 20 0.729167 19.8542 0.4375 19.5625C0.145833 19.2708 0 18.9167 0 18.5V3.5C0 3.08333 0.145833 2.72917 0.4375 2.4375C0.729167 2.14583 1.08333 2 1.5 2H6.625C6.70833 1.41667 6.975 0.9375 7.425 0.5625C7.875 0.1875 8.4 0 9 0C9.6 0 10.125 0.1875 10.575 0.5625C11.025 0.9375 11.2917 1.41667 11.375 2H16.5C16.9167 2 17.2708 2.14583 17.5625 2.4375C17.8542 2.72917 18 3.08333 18 3.5V11.725C17.5833 11.5083 17.15 11.35 16.7 11.25C16.25 11.15 15.7917 11.1 15.325 11.1C15.0917 11.1 14.8667 11.1125 14.65 11.1375C14.4333 11.1625 14.2167 11.2 14 11.25V10.25H4V11.75H12.6C12 12.05 11.4625 12.4375 10.9875 12.9125C10.5125 13.3875 10.125 13.9167 9.825 14.5H4V16H9.275C9.225 16.2167 9.1875 16.4333 9.1625 16.65C9.1375 16.8667 9.125 17.0917 9.125 17.325C9.125 17.8083 9.175 18.2667 9.275 18.7C9.375 19.1333 9.53333 19.5667 9.75 20Z" fill={color}/>
  </svg>
)
export default DocumentTablet