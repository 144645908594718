import { LocationTreeAdapter } from './LocationTreeAdapter'

type QueryParams = {
  projectId: number
  leafId: number
  withNeighbours: boolean
}

export class GetLocationBranchQuery {
  query = ({ projectId, leafId, withNeighbours = true }: QueryParams) => {
    return `projects/${projectId}/locations_tree_branch/${leafId}?with_neighbours=${withNeighbours}`
  }

  transformResponse = (response: unknown) => {
    return new LocationTreeAdapter(response).getFields()
  }
}