import { websocketMiddleware } from '../utils/websockets/middleware/websoketMiddleware'
import { configureStore, Middleware } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { projectUserApi } from '../api/projectUserApi/projectUserApi'
import { companyApi } from '../api/companyApi/companyApi'
import { projectApi } from '../api/projectApi/projectApi'
import { systemUserApi } from '../api/systemUserApi/systemUserApi'
import { projectRoleApi } from '../../fsd/shared/rtk/ProjectRole/ProjectRoleApi'
import { carCheckpointsApi } from '../api/carCheckpointApi/carCheckpointApi'
import { userCheckpointsApi } from '../api/userCheckpointApi/userCheckpointApi'
import { projectLocationsApi } from '../../fsd/shared/rtk/ProjectLocation/ProjectLocationApi'
import { checkpointLogsApi } from '../../fsd/shared/rtk/CheckpointLogs/checkpointLogsApi'
import { locationTreeApi } from '../api/locationTreeApi/locationTreeApi'
import rootReducer from './rootReducer'

export const setupStore = (initialState?: ReturnType<typeof rootReducer>) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware =>
      getDefaultMiddleware()
        .concat(
          projectUserApi.middleware,
          companyApi.middleware,
          projectApi.middleware,
          systemUserApi.middleware,
          projectRoleApi.middleware,
          carCheckpointsApi.middleware,
          userCheckpointsApi.middleware,
          projectLocationsApi.middleware,
          locationTreeApi.middleware,
          checkpointLogsApi.middleware,
          websocketMiddleware as Middleware
        )
    ),
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: initialState
  })

const store = setupStore()

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store