import { LocationLeafModel, LocationLeafAdapter } from './LocationLeafAdapter'

export interface LocationTreeModel {
  leafNeighbours: LocationLeafModel[]
  treeBranchNodes: LocationLeafModel[]
}

export class LocationTreeAdapter {

  private model: LocationTreeModel = {
    leafNeighbours: [],
    treeBranchNodes: []
  }

  constructor(data: any) {
    try {
      this.model.leafNeighbours = data.leaf_neighbours.map((d: any) => 
        new LocationLeafAdapter(d).getFields()
      )
      this.model.treeBranchNodes = data.tree_branch_nodes.map((d: any) => 
        new LocationLeafAdapter(d).getFields()
      )
    } catch(e) {
      console.error(e)
    }
  }

  getFields() {
    return this.model
  }
}

