import React from 'react'
import { IIcon } from './IIcon'

export const Trash: React.FC<IIcon> = ({ 
  width = '2.4', 
  height = '2.4', 
  color = 'black', 
  opacity = '0.64', 
  className='', 
  ...innerProps 
}) => (
  <div className={className} style={{height: `${height}rem`, width: `${width}rem`}}>
    <svg {...innerProps} width={`${width}rem`} height={`${height}rem`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z" fill={color} fillOpacity={opacity}/>
    </svg>
  </div>
)